/// <reference types="dom-webcodecs" />

'use strict';

/**
 * The chroma key shader works in the YUV color space,
 * using the U and V components to measure how far a pixel is from the key color.
 *
 * If the distance is below a threshold called similarity, the pixel is fully transparent.
 * Beyond that, the transparency rises. The smoothness parameter controls how quickly the transparency rises
 *
 * Similarly, the pixel is desaturated to the extent that its chrominance is close to the key color.
 * This attempts to account for light reflected from the subject
 * The spill parameter controls how quickly this desaturation drops off.
 */

export interface KeyColor {
  r: number;
  g: number;
  b: number;
}

/**
 * This Documention is intended to belong in docs/web-sdk/guides/scene-configurations.md
 * But it is kept here while this feature is not supported.
 * 
 * The file /dedicated-workers/chroma-key.worker.js must also be hosted at this path for the smwebsdk to load it
 * upon enabling of this feature. There are no consequences in its absence if this feature is not enabled
 * 
 * - optional `chromaKeyOptions`: By default, the Soul Machines Web SDK will  not apply chromaKey compositing. Optionally, chromaKey compositing can be activated by setting chomaKeyOptions to `{ enabled: true }`. There are more options for chromaKey settings.
 * ```
 * interface ChromaKeyOptions {
 *   enabled: boolean;     // enable or disable chromaKey compositing
 *   keyColor?: KeyColor;  // the background color to be made transparent.
 *                         // default value as {  r: 4 / 255, g: 244 / 255, b: 4 / 255}
 *   similarity?: number;  // The chroma key shader works in the YUV color space, using
 *                         // the U and V components to measure how far a pixel is from
 *                         // the key color.
 *                         // If the distance is below a threshold called similarity, the
 *                         // pixel is fully transparent. Beyond that, the transparency rises.
 *                         // default value is 0.4
 *   smoothness?: number;  // the pixel is desaturated to the extent that its chrominance
 *                         // is close to the key color.
 *                         // This attempts to account for light reflected from the subject
 *                         // default value is 0.08
 *   spill?: number;       // this parameter controls how quickly this desaturation drops off
 *                         // default value is 0.1
 * }

 * export interface KeyColor {
 *   r: number; // [0, 1]
 *   g: number; // [0, 1]
 *   b: number; // [0, 1]
 * }
 * ```
 */
export interface ChromaKeyOptions {
  enabled: boolean;
  keyColor?: KeyColor;
  similarity?: number;
  smoothness?: number;
  spill?: number;
}

let keyColor: KeyColor = {
  r: 4 / 255,
  g: 244 / 255,
  b: 4 / 255,
};
let similarity = 0.4;
let smoothness = 0.08;
let spill = 0.1;

export function updateChromaKeyOptions(options: ChromaKeyOptions) {
  keyColor = options.keyColor || keyColor;
  similarity = options.similarity || similarity;
  smoothness = options.smoothness || smoothness;
  spill = options.spill || spill;
}

export let offscreenCanvas: OffscreenCanvas | any;
let gl: any;
let buffer: any;
let visibleRect: any;
let format: string | null;

let keyColorLoc: any;
let similarityLoc: any;
let smoothnessLoc: any;
let spillLoc: any;

const videoFrameBufferInit: VideoFrameBufferInit = {
  timestamp: 0,
  codedWidth: 0,
  codedHeight: 0,
  format: 'RGBA',
};

const chromaKeyOptions = `
uniform vec3 keyColor;
uniform float similarity;
uniform float smoothness;
uniform float spill;
`;

const RGBtoUV = `
vec2 RGBtoUV(vec3 rgb) {
  return vec2(
    rgb.r * -0.169 + rgb.g * -0.331 + rgb.b *  0.5    + 0.5,
    rgb.r *  0.5   + rgb.g * -0.419 + rgb.b * -0.081  + 0.5
  );
}
`;

const processChromaKey = `
vec4 ProcessChromaKey(vec2 texCoord, vec4 rgba) {
    float chromaDist = distance(RGBtoUV(rgba.rgb), RGBtoUV(keyColor));
    float baseMask = chromaDist - similarity;
    float fullMask = pow(clamp(baseMask / smoothness, 0., 1.), 1.5);
    rgba.a = fullMask;
    float spillVal = pow(clamp(baseMask / spill, 0., 1.), 1.5);
    float desat = clamp(rgba.r * 0.2126 + rgba.g * 0.7152 + rgba.b * 0.0722, 0., 1.);
    rgba.rgb = mix(vec3(desat, desat, desat), rgba.rgb, spillVal);
    return rgba;
}
`;

const rgb_FragmentShader = `
precision highp float;

uniform sampler2D tex_y;
varying vec2 v_texCoord;

${chromaKeyOptions}

${RGBtoUV}

${processChromaKey}

void main() {
    vec4 video_pixels_rgba = texture2D(tex_y, v_texCoord);
    gl_FragColor = ProcessChromaKey(v_texCoord, video_pixels_rgba);
}
`;

const rgb_VertexShader = `
attribute vec4 a_vertexPosition;
attribute vec2 a_texturePosition;
varying vec2 v_texCoord;
void main(void)
{
  gl_Position = a_vertexPosition;
  v_texCoord = a_texturePosition;
}
`;

const i420_to_rgb_FragmentShader = `
precision highp float;

uniform sampler2D u_image_frame;
uniform vec2 u_resolution;
varying vec2 v_texCoord;

${chromaKeyOptions}

${RGBtoUV}

${processChromaKey}

void main() {
  float x_offset = mod( gl_FragCoord.y ,2.0  ) < 1.0 ? 0.5 : 0.0 ;
  vec4 y4 =  texture2D(u_image_frame, vec2 ( v_texCoord.x / 1.0  , v_texCoord.y / 1.5  )   )  ;
  vec4 u4 = (texture2D(u_image_frame, vec2 ( x_offset + v_texCoord.x  / 2.0  ,2.0/3.0 +  v_texCoord.y / 6.0  )   ) -0.50 ) * 2.0 ;
  vec4 v4 = (texture2D(u_image_frame, vec2 ( x_offset + v_texCoord.x / 2.0  , 5.0/6.0 + v_texCoord.y / 6.0  )   ) -0.50 ) * 2.0 ;   
  
  float y = y4[0] ;
  float u = u4[0] ;
  float v = v4[0] ;   

  float R = clamp(y + 1.13 * v, 0.0, 1.0) ;
  float G = clamp(y - 0.39 * u - 0.58 * v, 0.0, 1.0) ;
  float B = clamp(y + 2.03 * u, 0.0, 1.0) ;

  vec4 video_pixels_rgba = vec4( vec3(R,G,B), 1.0) ;

  gl_FragColor = ProcessChromaKey(vec2 ( v_texCoord.x / 1.0  , v_texCoord.y / 1.5  ), video_pixels_rgba);
}
`;

const i420_to_rgb_VertexShader = `
attribute vec2 a_position;
attribute vec2 a_texCoord;
uniform vec2 u_resolution; 
varying vec2 v_texCoord;
  
void main() {  
  gl_Position =  vec4(( (a_position / u_resolution * 2.0) - 1.0)  * vec2(1, -1), 0, 1);  
  v_texCoord = a_texCoord;  
}
`;

const n12_to_rgb_FragmentShader = `
precision highp float;
varying vec2 v_texCoord;
uniform sampler2D tex_y;
uniform sampler2D tex_uv;

${chromaKeyOptions}

${RGBtoUV}

${processChromaKey}

void main() 
{
    vec3 yuv, rgb;
    vec3 yuv2r = vec3(1.164, 0.0, 1.596);
    vec3 yuv2g = vec3(1.164, -0.391, -0.813);
    vec3 yuv2b = vec3(1.164, 2.018, 0.0);

    yuv.x = texture2D(tex_y, v_texCoord).r - 0.0625;
    yuv.y = texture2D(tex_uv, v_texCoord).r - 0.5;
    yuv.z = texture2D(tex_uv, v_texCoord).a - 0.5;

    rgb.x = dot(yuv, yuv2r);
    rgb.y = dot(yuv, yuv2g);
    rgb.z = dot(yuv, yuv2b);

    vec4 video_pixels_rgba  = vec4(rgb, 1.0);

    gl_FragColor = ProcessChromaKey(v_texCoord, video_pixels_rgba);
}
`;

const n12_to_rgb_VertexShader = `
attribute vec4 a_vertexPosition;
attribute vec2 a_texturePosition;
varying vec2 v_texCoord;
void main(void)
{
  gl_Position = a_vertexPosition;
  v_texCoord = a_texturePosition;
}
`;

function getGl(width: number, height: number) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  offscreenCanvas = new OffscreenCanvas(width, height);
  gl = offscreenCanvas.getContext('webgl', { premultipliedAlpha: false });
  if (gl) {
    return true;
  }
  return false;
}

function getCanvasGL(canvas: any) {
  gl = canvas.getContext('webgl', { premultipliedAlpha: false });
  if (gl) {
    return true;
  }
  return false;
}

function createShader(type: any, src: any) {
  const shader = gl.createShader(type);
  gl.shaderSource(shader, src);
  gl.compileShader(shader);
  if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
    console.error(gl.getShaderInfoLog(shader));
  }
  return shader;
}

function createProgram(vertexShader: any, fragmentShader: any) {
  const program = gl.createProgram();
  gl.attachShader(program, vertexShader);
  gl.attachShader(program, fragmentShader);
  gl.linkProgram(program);
  gl.useProgram(program);
  if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
    console.error(gl.getShaderInfoLog(program));
  }
  return program;
}

function createTexture(filter = gl.LINEAR) {
  const t = gl.createTexture();

  gl.bindTexture(gl.TEXTURE_2D, t);

  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);

  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, filter);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, filter);
  return t;
}

export async function initWebGL_rgb(width: number, height: number) {
  if (!getGl(width, height)) return;

  initWebGL_rgb_with_gl();
}

export function initWebGL_rgb_with_canvas(canvas: any) {
  if (!getCanvasGL(canvas)) return;

  initWebGL_rgb_with_gl();
}

function initWebGL_rgb_with_gl() {
  gl.pixelStorei(gl.UNPACK_ALIGNMENT, 1);

  const vs = createShader(gl.VERTEX_SHADER, rgb_VertexShader);
  const fs = createShader(gl.FRAGMENT_SHADER, rgb_FragmentShader);
  const program = createProgram(vs, fs);

  initVertexBuffers(program);

  gl.activeTexture(gl.TEXTURE0);
  gl.y = createTexture();
  gl.uniform1i(gl.getUniformLocation(program, 'tex_y'), 0);

  keyColorLoc = gl.getUniformLocation(program, 'keyColor');
  similarityLoc = gl.getUniformLocation(program, 'similarity');
  smoothnessLoc = gl.getUniformLocation(program, 'smoothness');
  spillLoc = gl.getUniformLocation(program, 'spill');
}

async function initWebGL_I420(width: number, height: number) {
  if (!getGl(width, height)) return;

  const vs = createShader(gl.VERTEX_SHADER, i420_to_rgb_VertexShader);
  const fs = createShader(gl.FRAGMENT_SHADER, i420_to_rgb_FragmentShader);
  const program = createProgram(vs, fs);

  const positionLocation = gl.getAttribLocation(program, 'a_position');
  const texcoordLocation = gl.getAttribLocation(program, 'a_texCoord');

  const positionBuffer = gl.createBuffer();

  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);

  // Set a rectangle the same size as the image.
  const x1 = 0;
  const x2 = 0 + width;
  const y1 = 0;
  const y2 = 0 + height;
  gl.bufferData(
    gl.ARRAY_BUFFER,
    new Float32Array([x1, y1, x2, y1, x1, y2, x1, y2, x2, y1, x2, y2]),
    gl.STATIC_DRAW
  );

  // provide texture coordinates for the rectangle.
  const texcoordBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, texcoordBuffer);
  gl.bufferData(
    gl.ARRAY_BUFFER,
    new Float32Array([
      0.0, 0.0, 1.0, 0.0, 0.0, 1.0, 0.0, 1.0, 1.0, 0.0, 1.0, 1.0,
    ]),
    gl.STATIC_DRAW
  );

  const textures = [];

  // create texture for the video frame
  {
    const texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture);

    // Set the parameters so we can render any size image.
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);

    const pixelData = new Uint8Array(1.5 * width * height);

    // Upload the image into the texture.
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.LUMINANCE,
      width,
      height * 1.5,
      0,
      gl.LUMINANCE,
      gl.UNSIGNED_BYTE,
      pixelData
    );

    textures[0] = texture;
  }

  const u_image_frame = gl.getUniformLocation(program, 'u_image_frame');

  // set which texture units to render with.
  gl.uniform1i(u_image_frame, 0); // texture unit 1

  gl.activeTexture(gl.TEXTURE0);
  gl.bindTexture(gl.TEXTURE_2D, textures[0]);

  gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

  gl.clearColor(0, 0, 0, 0);
  gl.clear(gl.COLOR_BUFFER_BIT);

  // Turn on the position attribute
  gl.enableVertexAttribArray(positionLocation);

  // Bind the position buffer.
  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);

  // Tell the position attribute how to get data out of positionBuffer (ARRAY_BUFFER)
  {
    const size = 2;
    const type = gl.FLOAT;
    const normalize = false;
    const stride = 0;
    const offset = 0;
    gl.vertexAttribPointer(
      positionLocation,
      size,
      type,
      normalize,
      stride,
      offset
    );
  }

  // Turn on the texcoord attribute
  gl.enableVertexAttribArray(texcoordLocation);

  // bind the texcoord buffer.
  gl.bindBuffer(gl.ARRAY_BUFFER, texcoordBuffer);

  // Tell the texcoord attribute how to get data out of texcoordBuffer (ARRAY_BUFFER)
  {
    const size = 2;
    const type = gl.FLOAT;
    const normalize = false;
    const stride = 0;
    const offset = 0;
    gl.vertexAttribPointer(
      texcoordLocation,
      size,
      type,
      normalize,
      stride,
      offset
    );
  }

  // lookup uniforms
  const resolutionLocation = gl.getUniformLocation(program, 'u_resolution');
  // set the resolution
  gl.uniform2f(resolutionLocation, gl.canvas.width, gl.canvas.height);

  keyColorLoc = gl.getUniformLocation(program, 'keyColor');
  similarityLoc = gl.getUniformLocation(program, 'similarity');
  smoothnessLoc = gl.getUniformLocation(program, 'smoothness');
  spillLoc = gl.getUniformLocation(program, 'spill');

  // Draw the rectangle.
  gl.drawArrays(gl.TRIANGLES, 0, 6);
}

function initVertexBuffers(program: any) {
  const vertexBuffer = gl.createBuffer();
  const vertexRectangle = new Float32Array([
    1.0, 1.0, 0.0, -1.0, 1.0, 0.0, 1.0, -1.0, 0.0, -1.0, -1.0, 0.0,
  ]);
  gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
  gl.bufferData(gl.ARRAY_BUFFER, vertexRectangle, gl.STATIC_DRAW);

  const vertexPositionAttribute = gl.getAttribLocation(
    program,
    'a_vertexPosition'
  );
  gl.vertexAttribPointer(vertexPositionAttribute, 3, gl.FLOAT, false, 0, 0);
  gl.enableVertexAttribArray(vertexPositionAttribute);

  const textureRectangle = new Float32Array([
    1.0, 0.0, 0.0, 0.0, 1.0, 1.0, 0.0, 1.0,
  ]);
  const textureBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, textureBuffer);
  gl.bufferData(gl.ARRAY_BUFFER, textureRectangle, gl.STATIC_DRAW);
  const textureCoord = gl.getAttribLocation(program, 'a_texturePosition');
  gl.vertexAttribPointer(textureCoord, 2, gl.FLOAT, false, 0, 0);
  gl.enableVertexAttribArray(textureCoord);
}

async function initWebGL_NV12(width: number, height: number) {
  if (!getGl(width, height)) return;

  gl.pixelStorei(gl.UNPACK_ALIGNMENT, 1);

  const vs = createShader(gl.VERTEX_SHADER, n12_to_rgb_VertexShader);
  const fs = createShader(gl.FRAGMENT_SHADER, n12_to_rgb_FragmentShader);
  const program = createProgram(vs, fs);

  initVertexBuffers(program);

  gl.activeTexture(gl.TEXTURE0);
  gl.y = createTexture();
  gl.uniform1i(gl.getUniformLocation(program, 'tex_y'), 0);

  gl.activeTexture(gl.TEXTURE1);
  gl.uv = createTexture();
  gl.uniform1i(gl.getUniformLocation(program, 'tex_uv'), 1);

  keyColorLoc = gl.getUniformLocation(program, 'keyColor');
  similarityLoc = gl.getUniformLocation(program, 'similarity');
  smoothnessLoc = gl.getUniformLocation(program, 'smoothness');
  spillLoc = gl.getUniformLocation(program, 'spill');
}

const ArrayBufferViewValue = Object.getPrototypeOf(
  Object.getPrototypeOf(new Uint8Array())
).constructor;

export function render_rgb(width: number, height: number, data: any) {
  gl.viewport(0, 0, width, height);

  gl.bindTexture(gl.TEXTURE_2D, gl.y);

  if (data instanceof ArrayBufferViewValue) {
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGBA,
      width,
      height,
      0,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      data
    );
  } else {
    // HTMLVideoElement
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, data);
  }

  gl.uniform3f(keyColorLoc, keyColor.r, keyColor.g, keyColor.b);
  gl.uniform1f(similarityLoc, similarity);
  gl.uniform1f(smoothnessLoc, smoothness);
  gl.uniform1f(spillLoc, spill);

  gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
  gl.flush();
  gl.finish();
}

function render_nv12(width: number, height: number, data: ArrayBuffer) {
  const uvOffset = width * height;

  const view = new Uint8Array(data);
  gl.bindTexture(gl.TEXTURE_2D, gl.y);
  gl.texImage2D(
    gl.TEXTURE_2D,
    0,
    gl.LUMINANCE,
    width,
    height,
    0,
    gl.LUMINANCE,
    gl.UNSIGNED_BYTE,
    view.subarray(0, uvOffset)
  );

  gl.bindTexture(gl.TEXTURE_2D, gl.uv);
  gl.texImage2D(
    gl.TEXTURE_2D,
    0,
    gl.LUMINANCE_ALPHA,
    width / 2,
    height / 2,
    0,
    gl.LUMINANCE_ALPHA,
    gl.UNSIGNED_BYTE,
    view.subarray(uvOffset)
  );

  gl.uniform3f(keyColorLoc, keyColor.r, keyColor.g, keyColor.b);
  gl.uniform1f(similarityLoc, similarity);
  gl.uniform1f(smoothnessLoc, smoothness);
  gl.uniform1f(spillLoc, spill);

  gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
  gl.flush();
  gl.finish();
}

function render_i420(width: number, height: number, data: ArrayBuffer) {
  const view = new Uint8Array(data);

  gl.activeTexture(gl.TEXTURE0);
  gl.texImage2D(
    gl.TEXTURE_2D,
    0,
    gl.LUMINANCE,
    width,
    height * 1.5,
    0,
    gl.LUMINANCE,
    gl.UNSIGNED_BYTE,
    view,
    0
  );

  gl.uniform3f(keyColorLoc, keyColor.r, keyColor.g, keyColor.b);
  gl.uniform1f(similarityLoc, similarity);
  gl.uniform1f(smoothnessLoc, smoothness);
  gl.uniform1f(spillLoc, spill);

  gl.drawArrays(gl.TRIANGLES, 0, 6);
  gl.flush();
  gl.finish();
}

async function transform(
  frame: VideoFrame,
  controller: TransformStreamDefaultController
) {
  // When called from RTCRtpScriptTransforms the frame is an RtcEncodedVideoFrame, which is before decoding.
  // It looks like we should be able to use WebCodecs API to decode the RtcEncodedVideoFrame, but that isnt available
  // on Safari yet...

  if (
    !gl ||
    format !== frame.format ||
    offscreenCanvas.width !== frame.codedWidth ||
    offscreenCanvas.height !== frame.codedHeight
  ) {
    if (frame.format === 'NV12') {
      initWebGL_NV12(frame.codedWidth, frame.codedHeight);
    } else if (frame.format === 'I420') {
      initWebGL_I420(frame.codedWidth, frame.codedHeight);
    }
    format = frame.format;

    // avoid error below:
    // Failed to execute 'allocationSize' on 'VideoFrame': Invalid visibleRect.
    // Expected height to be a multiple of 2 in plane 1 for format NV12; was 749.
    // Consider rounding visibleRect inward or outward to a sample boundary.
    let height = 0;
    if (frame.visibleRect) height = frame.visibleRect.height;
    const roundedFrameHeight = height - (height % 2);
    if (roundedFrameHeight !== height && frame.visibleRect) {
      const rect = {
        x: frame.visibleRect.x,
        y: frame.visibleRect.y,
        width: frame.visibleRect.width,
        height: roundedFrameHeight,
      };
      visibleRect = rect;
      buffer = new ArrayBuffer(frame.allocationSize({ rect: rect }));
    } else {
      visibleRect = frame.visibleRect;
      buffer = new ArrayBuffer(frame.allocationSize({ rect: visibleRect }));
    }
    videoFrameBufferInit.codedWidth = frame.codedWidth;
    videoFrameBufferInit.codedHeight = frame.codedHeight;
  }

  if (visibleRect) {
    try {
      await frame.copyTo(buffer, { rect: visibleRect });
    } catch (error) {
      return;
    }

    if (frame.format === 'NV12') {
      render_nv12(visibleRect.width, visibleRect.height, buffer);
    } else if (frame.format === 'I420') {
      render_i420(visibleRect.width, visibleRect.height, buffer);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  videoFrameBufferInit.timestamp = frame.timestamp!;

  const newFrame = new VideoFrame(gl.canvas, videoFrameBufferInit);

  frame.close();
  controller.enqueue(newFrame);
}

function handleTransform(
  readable: ReadableStream<VideoFrame>,
  writable: WritableStream<VideoFrame>
) {
  readable.pipeThrough(new TransformStream({ transform })).pipeTo(writable);
}

function isUnderJest(): boolean {
  return (
    typeof process !== 'undefined' &&
    typeof process.env !== 'undefined' &&
    typeof process.env.JEST_WORKER_ID !== 'undefined'
  );
}

if (!isUnderJest() && typeof onmessage !== 'undefined') {
  onmessage = async (event: MessageEvent<any>) => {
    if (!event.data) return;

    if (event.data.readable && event.data.writable) {
      return handleTransform(event.data.readable, event.data.writable);
    } else if (event.data.chromaKeyOptions) {
      updateChromaKeyOptions(event.data.chromaKeyOptions);
    }
  };
}

// Handler for RTCRtpScriptTransforms.
if (typeof self !== 'undefined' && (self as any).RTCTransformEvent) {
  (self as any).onrtctransform = (event: any) => {
    const transformer = event.transformer;
    handleTransform(transformer.readable, transformer.writable);
  };
}
