/// <reference types="dom-mediacapture-transform" />

/**
 * @module smwebsdk
 */

/*
 * Copyright 2017-2020 Soul Machines Ltd. All Rights Reserved.
 */

import { Deferred } from './Deferred';
import { Features } from './Features';
import { SmEvent } from './SmEvent';
import { Logger, LogLevel } from './utils/Logger';
import { WebsocketResponse } from './websocket-message/index';
import { UserMedia } from './types/scene';
import { makeError } from './utils/make-error';
import { ConnectionStateTypes } from './enums/ConnectionStateTypes';
import { ConnectionState } from './ConnectionState';
import {
  ChromaKeyOptions,
  initWebGL_rgb_with_canvas,
  render_rgb,
  updateChromaKeyOptions,
} from './dedicated-workers/chroma-key.worker';

// Reexporting here to keep backwards compatibility
export { UserMedia } from './types/scene';

export interface MessageFunction {
  (message: string): void;
}

export interface WebsocketFunction {
  (message: WebsocketResponse): void;
}

export interface SessionFunction {
  (
    resumeRequested: boolean,
    isResumedSession: boolean,
    server: string,
    sessionId: string
  ): void;
}

export interface ConnectionStateFunction {
  (connectionState: ConnectionStateTypes): void;
}

interface JwtResponse {
  url: string;
  jwt: string;
}

interface ChangeUserMediaOp {
  deferred: Deferred<any>;
  microphone: boolean | undefined;
  camera: boolean | undefined;
}

interface RemoveListener {
  target: any;
  name: string;
  callback: any;
}

/**
 *  Session class
 */
export class Session {
  public videoMediaStream!: MediaStream | null;
  private _videoElement: HTMLVideoElement;
  private _chromaKeyedCanvas: HTMLCanvasElement | null = null;
  private _videoElementStyleObserver: MutationObserver | null = null;
  private _serverUri: string;
  private _connectUserText: string;
  private _accessToken: string;
  private _peerConnection!: RTCPeerConnection;
  private _localStream!: MediaStream | null;
  private _remoteStream!: MediaStream | null;
  // eslint-disable-next-line @typescript-eslint/ban-types
  private _connectPendingRemoteStream: Function | null = null;
  private _serverConnection!: WebSocket;
  private _sessionId!: string;
  private _resumeRequested = false;
  private _isResumedSession = false;
  private _outgoingQueue: any[] = [];

  private _server!: string;
  private _sceneId!: number;

  private _controlUrl!: string;
  private _controlConnection!: WebSocket;
  private _controlOpen = false;
  private _controlQueue: any[] = [];

  private _chromaKeyOptions: ChromaKeyOptions | undefined = undefined;

  private _audioOnly: boolean;
  private _requestedUserMedia: UserMedia = UserMedia.None;
  private _requiredUserMedia: UserMedia = UserMedia.None;

  private _onConnected: SessionFunction = (
    resumeRequested: boolean,
    isResumedSession: boolean,
    server: string,
    sessionId: string
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  ) => {};
  private _onClose: MessageFunction;
  private _onMessage: WebsocketFunction;
  private _onUserText: MessageFunction;
  private _sessionError: MessageFunction;

  private _pendingLog: string[] = [];

  private _closed = false;

  private _shouldLogToServer = false;

  private _features: Features;

  private _connectionState: ConnectionState;

  private _logger: Logger;

  // Duration that microphone mute is maintained by the web sdk after the persona has
  // finished speaking.  Set to -1 to disable.  Default value is -1 (disabled) if not specified
  // by server in 'established' message.
  private _microphoneMuteDelay = -1;

  private _changeUserMediaQueue: Array<ChangeUserMediaOp> =
    new Array<ChangeUserMediaOp>();

  private _onMicrophoneActive?: SmEvent;
  private _onCameraActive?: SmEvent;

  private _removeListeners: Array<RemoveListener> = new Array<RemoveListener>();

  private _videoOptions: MediaTrackConstraints = {
    frameRate: 10.0,
    width: 640.0,
    height: 480.0,
    facingMode: 'user',
  };

  // TypeScript support to MediaTrackConstraints is not complete, thus using any here
  private _audioOptions: Record<string, any> = {
    noiseSuppression: false,
    autoGainControl: false,
    channelCount: 1,
    sampleRate: 16000,
    sampleSize: 16,
    echoCancellation: true,
  };

  constructor(
    videoElement: HTMLVideoElement,
    serverUri: string,
    connectUserText: string | undefined,
    accessToken: string,
    audioOnly: boolean,
    requestedUserMedia: UserMedia,
    requiredUserMedia: UserMedia,
    echoCancellationEnabled: boolean,
    logger: Logger,
    connectionState: ConnectionState,
    chromaKeyOptions?: ChromaKeyOptions
  ) {
    this._videoElement = videoElement;
    this._serverUri = serverUri;
    this._connectUserText = connectUserText || '';
    this._accessToken = accessToken;
    this._audioOnly = audioOnly;
    this._audioOptions.echoCancellation = echoCancellationEnabled;
    this._requiredUserMedia = requiredUserMedia;
    this._requestedUserMedia = requestedUserMedia;
    this._logger = logger;
    this._chromaKeyOptions = chromaKeyOptions;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this._onClose = (reason: string) => {}; // owner specifies custom close method
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this._onMessage = (message: WebsocketResponse) => {}; // owner specifies custom message handler
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this._onUserText = (text: string) => {}; // owner specifies custom rtc user text message handler

    this._sessionError = (error: string) => {
      // owner can specify custom session error handler
      this.log(`session error: ${error}`, 'error');
    };

    this._features = new Features();
    this._connectionState = connectionState;
  }

  public setVideoElement(videoElement: HTMLVideoElement) {
    if (videoElement === this._videoElement) {
      return;
    }
    if (this._videoElement) {
      this._videoElement.removeEventListener(
        'loadeddata',
        this.onVideoLoaded.bind(this)
      );
      this._removeListeners = this._removeListeners.filter(
        (removeLister) => removeLister.target !== this._videoElement
      );

      if (videoElement) {
        videoElement.srcObject = this._videoElement.srcObject;
      }
    }

    this._videoElementStyleObserver?.disconnect();
    this._videoElement = videoElement;
    this.setupVideoElement();

    if (this._chromaKeyOptions?.enabled) this.restoreChromaKeySettings();
  }

  private restoreChromaKeySettings() {
    if (!this._videoElement) return;

    // restore chromaKey settings
    if (this._videoElement.srcObject) {
      // Session must have been connected before if video element's srcObject was set.
      // For browsers support insertableStreams and transferableStreams, the video element's
      // srcObject was alreayd a stream has been chromaKeyd, so nothing more is needed.
      if (
        !(this.supportsInsertableStreams && this.supportsTransferableStreams) &&
        this._chromaKeyedCanvas
      ) {
        // For browsers doesn't support insertableStreams and transferableStreams,
        // chromaKeyedCanvas was created to display the chromaKeyed video, and the below
        // needs to be done.

        // Move chromaKeyedCanvas to the sibling position of the video element
        this._chromaKeyedCanvas.parentElement?.removeChild(
          this._chromaKeyedCanvas
        );
        this._videoElement.parentElement?.appendChild(this._chromaKeyedCanvas);
        this.copyStyleFromVideoElementToCanvas();
        this.hideVideoElement(this._videoElement);
        this.observeAndCopyStyleChangesFromVideoElementToCanvas();

        if ('requestVideoFrameCallback' in HTMLVideoElement.prototype) {
          // For browsers support requestVideoFrameCallback, the callback needs
          // to be called again on the new video element
          this._videoElement.requestVideoFrameCallback(
            this.processFrameForChromaKey.bind(this)
          );
        }
        // Else for browsers doesn't support requestVideoFrameCallback,
        // nothing more needs to be restored, because requestAnimationFrame
        // has alread been called recursively with this._videoElement after
        // session was connected.
      }
    } else if (this._remoteStream) {
      // If video element's srcObject was not set, but remoteSteam has been set,
      // the video element must be null when onConnectedSuccess() was called.
      // so setup chromaKeyed video from scratch.
      this.setupChromaKeyedVideo();
    }
    // Else session hasn't been connected, chromaKeyed video will be setup after session is connected.
  }

  get chromaKeyedCanvas(): HTMLCanvasElement | null {
    return this._chromaKeyedCanvas;
  }

  set onConnected(sessionFunction: SessionFunction) {
    this._onConnected = sessionFunction;
  }

  set onClose(closeFunction: MessageFunction) {
    this._onClose = closeFunction;
  }

  set onMessage(messageFunction: WebsocketFunction) {
    this._onMessage = messageFunction;
  }

  set onUserText(userTextFunction: MessageFunction) {
    this._onUserText = userTextFunction;
  }

  /**
   * @deprecated use Scene
   */
  set loggingEnabled(enable: boolean) {
    this.log(
      'loggingEnabled is deprecated and will be removed in a future version. Please use setLogging(boolean)',
      'warn'
    );

    this._logger.enableLogging(enable);
  }

  /**
   * @deprecated use Scene method
   */
  get loggingEnabled(): boolean {
    return this._logger.isEnabled;
  }

  /**
   * @deprecated use Scene method
   */
  public setMinLogLevel(level: LogLevel) {
    this._logger.setMinLogLevel(level);
  }

  /**
   * @deprecated use Scene method
   */
  public setLogging(enable: boolean) {
    this._logger.enableLogging(enable);
  }

  public log(text: string, level: LogLevel = 'debug') {
    if (this._logger.isEnabled) {
      const now = new Date();
      const msg = `smsdk: ${now.toISOString()}: ${text}`;

      if (this._shouldLogToServer) {
        this.logToServer(msg);
      }
      this._logger.log(level, msg);
    }
  }

  private logToServer(msg: string) {
    if (this.sessionId) {
      this.sendlogMessage([msg]);
    } else {
      this._pendingLog.push(msg);
    }
  }

  public sendlogMessage(textArray: string[]): void {
    if (this._sessionId && textArray && textArray.length > 0) {
      const payload: any = {
        category: 'diagnostics',
        kind: 'event',
        name: 'log',
        body: { name: 'browser', text: textArray },
      };
      this.sendMessage(payload);
    }
  }

  public async connect(userText?: string): Promise<string | undefined | any> {
    const deferred = new Deferred<any>();

    this._closed = false;
    if (userText) {
      this._connectUserText = userText;
    }

    if (
      this._serverUri &&
      (this._serverUri.startsWith('ws:') || this._serverUri.startsWith('wss:'))
    ) {
      // A server uri has been specified, continue with the connection
      // by acquiring user media (microphone/camera) as needed
      this.selectUserMedia(
        this._requestedUserMedia,
        this._requiredUserMedia,
        deferred,
        this.getUserMediaSuccess.bind(this)
      );
      return deferred.promise;
    }

    // Attempt to use the implicit /api/jwt REST get url to aquire
    // an access token from the server this site was served from
    const xhr = new XMLHttpRequest();
    xhr.open('GET', '/api/jwt' + window.location.search);
    xhr.onreadystatechange = async (ev: Event) => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          this.log(`JWT request returned: ${xhr.responseText}`);
          const response = JSON.parse(xhr.responseText) as JwtResponse;
          this._serverUri = response.url;
          this._accessToken = response.jwt;

          this.selectUserMedia(
            this._requestedUserMedia,
            this._requiredUserMedia,
            deferred,
            this.getUserMediaSuccess.bind(this)
          );
        } else {
          this.log(`JWT Request failed, status: ${xhr.statusText}`, 'error');
          deferred.reject(
            makeError('Failed to acquire jwt at /api/jwt', 'noServer')
          );
        }
      }
    };

    xhr.send();
    return deferred.promise;
  }

  private webcamRequested(
    requestedMedia: UserMedia,
    requiredMedia: UserMedia
  ): boolean {
    return (
      !this._audioOnly &&
      [UserMedia.MicrophoneAndCamera, UserMedia.Camera].some((r) =>
        [requestedMedia, requiredMedia].includes(r)
      )
    );
  }

  private micRequested(requestedMedia: UserMedia, requiredMedia: UserMedia) {
    return [UserMedia.Microphone, UserMedia.MicrophoneAndCamera].some((r) =>
      [requestedMedia, requiredMedia].includes(r)
    );
  }

  public getMediaConstraints(
    requestedMedia: UserMedia,
    requiredMedia: UserMedia
  ): MediaStreamConstraints {
    // checking supported constraints only for debugging purpose, no need to use it when applying constraints
    // as providing specific values for constraints, means they are a 'best effort' rather than required
    // https://developer.mozilla.org/en-US/docs/Web/API/Media_Streams_API/Constraints#requesting_a_specific_value_for_a_setting

    const supports = navigator.mediaDevices.getSupportedConstraints() as any;
    this.log(`Browser supports media constraints: ${supports}`);

    return {
      audio: this.micRequested(requestedMedia, requiredMedia)
        ? this.buildAudioOptions()
        : false,
      video: this.webcamRequested(requestedMedia, requiredMedia)
        ? this._videoOptions
        : false,
    };
  }

  private buildAudioOptions() {
    const supportedConstraints: Record<string, any> =
      navigator.mediaDevices.getSupportedConstraints();
    const constraints = { ...this._audioOptions };

    // Remove unknown and unsupported constraints, as these were causing errors in the latest version on Safari
    Object.keys(constraints).forEach((constraint) => {
      if (!supportedConstraints[constraint]) {
        delete constraints[constraint];
      }
    });

    return constraints;
  }

  selectUserMedia(
    requestedMedia: UserMedia,
    requiredMedia: UserMedia,
    deferred: Deferred<any>,
    completion: (stream: MediaStream | null, deferred: Deferred<any>) => any
  ) {
    if (requestedMedia === UserMedia.None && requiredMedia === UserMedia.None) {
      // no microphone or camera is required or requested
      completion(null, deferred);
      return;
    }

    if (navigator.mediaDevices.getUserMedia) {
      const constraints = this.getMediaConstraints(
        requestedMedia,
        requiredMedia
      );
      this.log(`Best video constraints: ${constraints}`);

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          completion(stream, deferred);
        })
        .catch((error: DOMException) => {
          //fail when required media wasn't obtained
          if (requiredMedia === requestedMedia) {
            this.log(
              `getUserMedia could not get required media, error given: ${error}`,
              'error'
            );

            deferred.reject(this.MakeErrorForUserMedia(error));
          }
          //re-try required media fallback
          else if (requiredMedia !== UserMedia.None) {
            this.getUserMediaRequiredOnlyFallback(
              requiredMedia,
              deferred,
              completion
            );
          }
          //re-try mic only fallback
          else if (requestedMedia === UserMedia.MicrophoneAndCamera) {
            this.getUserMediaAudioOnlyFallback(deferred, completion);
          }
          //complete without a stream
          else {
            completion(null, deferred);
          }
        });
    } else {
      deferred.reject(
        makeError(
          'Your browser does not support getUserMedia API',
          'notSupported'
        )
      );
    }
  }

  private getUserMediaRequiredOnlyFallback(
    requiredMedia: UserMedia,
    deferred: Deferred<any>,
    completion: (stream: MediaStream | null, deferred: Deferred<any>) => any
  ) {
    this.log('Retrying with required media only');
    const constraints = this.getMediaConstraints(UserMedia.None, requiredMedia);
    this.log(`Attempt constraints: ${constraints}`);
    return navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        completion(stream, deferred);
      })
      .catch((error: DOMException) => {
        this.log(
          `getUserMedia could not get required media, error given: ${error}`,
          'error'
        );
        //fail when required media wasn't obtained
        deferred.reject(this.MakeErrorForUserMedia(error));
      });
  }

  private getUserMediaAudioOnlyFallback(
    deferred: Deferred<any>,
    completion: (stream: MediaStream | null, deferred: Deferred<any>) => any
  ) {
    this.log('Retrying with microphone only');
    const constraints = {
      video: false,
      audio: this.buildAudioOptions(),
    };
    this.log(`Attempt constraints: ${constraints}`);
    return navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        completion(stream, deferred);
      })
      .catch((error: DOMException) => {
        this.log(
          `getUserMedia could not get microphone audio, error given: ${error}`,
          'error'
        );

        // still succeed as fallback is only tried if media was required, not requested
        completion(null, deferred);
      });
  }

  private MakeErrorForUserMedia(error: DOMException): Error {
    const name = 'noUserMedia';
    // Returning more specific errors below is considered a breaking change which we
    // cannot accommodate currently. This will be reinstated in the future, likely for v15
    // At that time, the error codes in Scene.connect() and scene.setMediaDeviceActive() need re-documenting -
    // see https://github.com/soulmachines/smwebsdk/blob/dfe3e1dc8d57aac17cb0be38fa2527190cd78ef4/src/Scene.ts#L916-L918

    // if (error.name === 'NotAllowedError' || error.name === 'SecurityError') {
    //   name = 'userMediaNotAllowed';
    // } else if (
    //   error.name === 'AbortError' ||
    //   error.name === 'NotReadableError'
    // ) {
    //   name = 'userMediaFailed';
    // }
    return makeError(error.message, name);
  }

  private getUserMediaSuccess(
    stream: MediaStream | null,
    deferred: Deferred<any>
  ): void {
    this.log('Got user media');

    this._localStream = stream;
    this.microphoneMuted = true; // mute the local microphone until the DP is visible

    // Connect to the session server

    // To pass the Json Web Token (jwt) to the server when opening the websocket
    // we basically have three options with the available javascript api:
    // 1) pass as a 'access_token' query parameter similar to auth2 bearer tokens when not auth header
    // 2) pass as basic auth user/pass embedded in the url
    // 3) pass as a custom protocol which is translated into the 'Sec-WebSocket-Protocol' request header
    //    more info here: https://stackoverflow.com/questions/4361173/http-headers-in-websockets-client-api
    // We've gone with using a query parameter with ssl.

    // create and connect the websocket
    // Note that javscript websockets do not allow most request headers to be set.  The two exceptions
    // are the Authorization header (via basic auth) and the protocol header - neither of which
    // are ideal for our jwt token.  Hence instead we pass the jwt as a query parameter.
    this.log(`connecting to: ${this._serverUri}`);
    if (!this._accessToken) {
      this._serverConnection = new WebSocket(this._serverUri);
    } else {
      this._serverConnection = new WebSocket(
        this._serverUri + '?access_token=' + this._accessToken
      );
    }

    this._serverConnection.onmessage = (msg: MessageEvent) => {
      try {
        this.gotMessageFromServer(msg, deferred);
      } catch (e) {
        this.log(
          `unexpected exception processing received message: ${e}`,
          'error'
        );
      }
    };

    this._serverConnection.onerror = (event) => {
      if (deferred.isPending()) {
        deferred.reject(
          makeError('websocket failed', 'serverConnectionFailed')
        );
      }
    };

    // wait for the websocket to open, then continue with setup
    this._serverConnection.onopen = (event: Event) => {
      this.log('Websocket open');
      // wait for the welcome 'established' message to receive the ice servers, hence nothing more to do here
      // websocket open - searching for an available DP scene, may require queuing
      this._connectionState.setConnectionState(
        ConnectionStateTypes.SearchingForDigitalPerson
      );
    };

    // setup a close handler
    this._serverConnection.onclose = (event: CloseEvent) => {
      this.log(
        `websocket closed: code(${event.code}), reason(${event.reason}), clean(${event.wasClean})`
      );
      if (!deferred.isRejected) {
        this.close(false, 'normal', deferred);
      }
    };
  }

  private hasTurnServer(iceServers: RTCIceServer[]): boolean {
    // Check for at least one turn server by url in the array of ice servers
    if (!iceServers) {
      return false;
    }

    for (const server of iceServers) {
      if (!server || !server.urls) {
        continue;
      }

      for (const url of server.urls) {
        if (url.indexOf('turn:') === 0) {
          return true;
        }
      }
    }

    return false;
  }

  private gotMessageFromServer(
    websocket_message: MessageEvent,
    deferred?: Deferred<any>
  ): void {
    const raw_text = websocket_message.data;
    this.log(`message received: ${raw_text}`);
    const message = JSON.parse(raw_text);

    const category = message.category;
    const name = message.name;
    const body = message.body;

    if (category !== 'webrtc') {
      // If there is a control connection then forward 'scene' messages to that
      if (this._controlConnection !== null && category === 'scene') {
        if (
          this._controlOpen &&
          this._serverConnection.readyState === WebSocket.OPEN
        ) {
          this._controlConnection.send(raw_text);
        } else {
          this._controlQueue.push(raw_text);
        }
      }

      // forward on non-webrtc messages (e.g. scene)
      this._onMessage(message);
      return;
    }

    if (message.kind !== 'event') {
      // currently ignore requests and responses
      return;
    }

    if (name === 'established') {
      // established - scene is available/found, downloading/preparing DP assets on the server
      this._connectionState.setConnectionState(
        ConnectionStateTypes.DownloadingAssets
      );

      // Create the peer connection configuration from the established body
      // which includes the ice servers we should use
      const config: RTCConfiguration = { iceServers: [] };
      if (body.iceServers) {
        config.iceServers = body.iceServers;

        // If at least one ice server is a turn server then force
        // our traffic to route over turn (relay)
        if (this.hasTurnServer(body.iceServers)) {
          this.log('Detected turn server, forcing relay mode');
          config.iceTransportPolicy = 'relay';
        }
      }
      this.log(`selected ice servers: ${config.iceServers}`);

      if (
        body.settings &&
        typeof body.settings.microphoneMuteDelay === 'number'
      ) {
        this._microphoneMuteDelay = body.settings.microphoneMuteDelay;
      }
      this.log(
        `microphone mute delay after persona speech: ${this._microphoneMuteDelay}`
      );

      // Send logging to server if requested by the server
      this._shouldLogToServer = body.settings?.logToServer ?? false;

      // Setup the WebRTC peer connection
      this._peerConnection = new RTCPeerConnection(config);
      // ref: Ice candidate will only trigger when media video is enabled in Safari
      // https://stackoverflow.com/a/53914556
      this._peerConnection.onicecandidate = this.gotIceCandidate.bind(this);
      if ('ontrack' in this._peerConnection && !this._features.isEdge) {
        // todo update when angular 7
        // This doesnt work with angular yet because of old definitions
        // this._peerConnection.ontrack = (event:RTCTrackEvent)=>{
        (this._peerConnection as any).ontrack = (event: any) => {
          if (event.track.kind === 'video' || event.track.kind === 'audio') {
            if (
              !this._remoteStream ||
              (!this._audioOnly && event.track.kind === 'video')
            ) {
              this.onRemoteStream(event.streams[0]);
            }
          }
        };

        this.setupVideoElement();
      } else {
        // fallback to stream (for IE/Safari plugin)
        // onaddstream is deprecated
        // This feature has been removed from the Web standards.
        // Though some browsers may still support it, it is in the process of being dropped.
        // Writing like this to pass type checking as this isnt in current spec and therefore
        // neither the type definitions
        (this._peerConnection as any).onaddstream = (
          // as any because MediaStreamEvent has been removed from lib.dom.d.ts
          streamEvent: any
        ) => {
          this.onRemoteStream(streamEvent.stream);
        };
      }
      this._peerConnection.oniceconnectionstatechange = (e) => {
        // `this._peerConnection.iceConnectionState === 'disconnected'` is quite handy
        this.log(
          `ICE connection state: ${this._peerConnection.iceConnectionState}`
        );
        if (this._peerConnection.iceConnectionState === 'failed') {
          makeError('ice connection failed', 'mediaStreamFailed');
          if (deferred && deferred.isPending()) {
            // Close the connection and reject the connect()
            this._serverConnection.close();
            if (
              this._controlConnection &&
              (this._controlConnection.readyState === WebSocket.OPEN ||
                this._controlConnection.readyState === WebSocket.CONNECTING)
            ) {
              this._controlConnection.close();
            }
            deferred.reject(
              makeError('ice connection failed', 'mediaStreamFailed')
            );
          }
        }
      };
      this.log('adding local media stream if any');
      if (this._localStream) {
        if (!(this._peerConnection as any).addTrack) {
          (this._peerConnection as any).addStream(this._localStream);
          this.log('adding local media stream by stream');
        } else {
          try {
            this.log('adding local media stream by track');
            this._localStream.getTracks().forEach((track) => {
              (this._peerConnection as any).addTrack(
                track,
                this._localStream as MediaStream
              );
            });
          } catch (e) {
            this.log(`error: ${e}`, 'error');
          }
        }
      }

      // Add an audio and video transceiver that are send and receive,
      // regardless of whether the user microphone / camera is currently
      // available.
      this._peerConnection.addTransceiver('audio', { direction: 'sendrecv' });
      this._peerConnection.addTransceiver('video', { direction: 'sendrecv' });

      // create updateOffer if resumeSessionId exists
      if (body.resumeSessionId) {
        const offerOptions: any = {
          voiceActivityDetection: false,
          iceRestart: true,
        };
        this._sessionId = body.resumeSessionId;
        this._isResumedSession = true;
        this.log(
          `established, trying to resume session with session_id = ${body.resumeSessionId}`
        );
        this.createOffer(this._peerConnection, offerOptions)
          .then((sessionDescription: RTCSessionDescriptionInit) => {
            this.createdDescription.bind(this);
            this.createdDescription(sessionDescription, 'updateOffer');
          })
          .catch(this._sessionError.bind(this));
      } else {
        // Create a webrtc offer
        const offerOptions: any = {
          voiceActivityDetection: false,
          iceRestart: false,
        };
        this._isResumedSession = false;
        this.createOffer(this._peerConnection, offerOptions)
          .then(this.createdDescription.bind(this))
          .catch(this._sessionError.bind(this));
      }
    } else if (name === 'accepted') {
      // accepted - DP is starting / forming webrtc connection
      this._connectionState.setConnectionState(
        ConnectionStateTypes.ConnectingToDigitalPerson
      );

      this.log(`accepted, session_id = ${body.sessionId}`);

      this._sessionId = body.sessionId;
      this._resumeRequested = body.resumeRequested;

      this._server = body.server;
      this._sceneId = body.sceneId;

      // The session has been accepted, send any outgoing queued messages
      for (let i = 0; i < this._outgoingQueue.length; i++) {
        this._outgoingQueue[i].body.sessionId = this._sessionId;
        this.sendMessage(this._outgoingQueue[i]);
      }
      this._outgoingQueue = [];

      // Monitor for orientation change events to update the camera rotation
      const callback = () => {
        if (this) {
          this.sendCameraRotation();
        }
      };
      window.addEventListener('orientationchange', callback);
      this._removeListeners.push({
        target: window,
        name: 'orientationchange',
        callback: callback,
      });

      this.sendCameraRotation();
      const view = document.defaultView || window;
      const style = view.getComputedStyle(this._videoElement);
      const video_width = parseInt(`${style.width}`, 10); // cs check changed frm this.videoElement
      const video_height = parseInt(`${style.height}`, 10);
      this.log(
        `accepted, sending video width/height: ${video_width} / ${video_height}`
      );
      this.sendVideoBounds(video_width, video_height);

      // Send all pending log messages
      this.sendlogMessage(this._pendingLog);
      this._pendingLog = [];

      // Check whether the the server needs us to route control messages to the
      // orchestration server.
      if (body.controlUrl) {
        this._controlUrl = body.controlUrl;
        this._controlQueue = [];
        this._controlOpen = false;
        this._controlConnection = new WebSocket(
          body.controlUrl + '?access_token=' + this._accessToken
        );

        this._controlConnection.onmessage = (msg: MessageEvent) => {
          const raw_text = msg.data;
          if (raw_text) {
            // forward this message to the session server
            if (this._serverConnection.readyState === WebSocket.OPEN) {
              this._serverConnection.send(raw_text);
            }
          }
        };
        this._controlConnection.onerror = () => {
          this.close(true, 'controlFailed', deferred);
        };

        // wait for the websocket to open, then continue with setup
        this._controlConnection.onopen = (event: Event) => {
          this.log('control websocket open');
          if (!this._controlOpen) {
            this._controlOpen = true;

            // send any pending orchestration/control messages in the order they were received
            for (let i = 0; i < this._controlQueue.length; i++) {
              this.log(
                `control websocket now open, forwarding queued message: ${this._controlQueue[i]}`
              );
              this._controlConnection.send(this._controlQueue[i]);
            }

            this._controlQueue = [];
          }
        };

        // setup a close handler
        this._controlConnection.onclose = (event: CloseEvent) => {
          this.log(
            `control closed: code(${event.code}), reason(${event.reason}), clean(${event.wasClean})`
          );
          this.close(true, 'controlDisconnected', deferred);
        };
      }
    } else if (name === 'answer') {
      this.log('set remote description');
      this.log(JSON.stringify(body));

      const sessionDescription: RTCSessionDescriptionInit = {
        sdp: body.sdp,
        type: 'answer',
      };
      this.setRemoteDescription(this._peerConnection, sessionDescription)
        .then(() => {
          // Currently there's nothing to do
        })
        .catch(this._sessionError.bind(this));
    } else if (name === 'connected') {
      if (this._remoteStream) {
        // connected - DP is started and ready the webrtc session has connected
        this._connectionState.setConnectionState(
          ConnectionStateTypes.Connected
        );
        this.onConnectedSuccess();
        if (deferred) {
          deferred.resolve(body.sessionId);
        }
      } else {
        this.log('Connected but no remote media stream available');
        // The remote stream has not connected yet, give it more time to connect
        this._connectPendingRemoteStream = () => {
          this.onConnectedSuccess();
          if (deferred) {
            deferred.resolve(body.sessionId);
          }
        };
      }
    } else if (name === 'ice') {
      this.log('add ice candidate');
      let addCandidate: Promise<void> | undefined;
      if (body.complete) {
        if (this._features.isEdge) {
          addCandidate = this._peerConnection.addIceCandidate(
            new RTCIceCandidate({
              candidate: '',
              sdpMid: '',
              sdpMLineIndex: 0,
            })
          );
        }
      } else {
        const iceCandidate = new RTCIceCandidate({
          candidate: body.candidate,
          sdpMid: body.sdpMid,
          sdpMLineIndex: body.sdpMLineIndex,
        });
        addCandidate = this._peerConnection.addIceCandidate(iceCandidate);
      }
      if (addCandidate) {
        addCandidate.catch(this._sessionError.bind(this));
      }
    } else if (name === 'offer') {
      // NB: For calls inbound to browser, currently not used
      //     Perhaps we might use this for queueing to talk to an avatar?
      this._sessionId = body.sessionId;

      const sessionDescription: RTCSessionDescriptionInit = {
        sdp: body.sdp,
        type: 'offer',
      };
      this.setRemoteDescription(this._peerConnection, sessionDescription)
        // Create an answer in response to the offer
        .then(() => this.createAnswer(this._peerConnection))
        .then(this.createdDescription.bind(this))
        .catch(this._sessionError.bind(this));
    } else if (name === 'userText') {
      this.log(`rtc - user text message received: ${body.userText}`);
      this._onUserText(body.userText);
    } else if (name === 'close') {
      this.close(false, body.reason, deferred);
    }
  }

  private setupVideoElement() {
    if (this._videoElement) {
      // Attach a video loaded event so the server can be notified when the video is
      // ready to start playing
      this._videoElement.addEventListener(
        'loadeddata',
        this.onVideoLoaded.bind(this)
      );
      this._removeListeners.push({
        target: this._videoElement,
        name: 'loadeddata',
        callback: this.onVideoLoaded,
      });
    }
  }

  private gotIceCandidate(event: RTCPeerConnectionIceEvent) {
    if (event.candidate) {
      this.log('got local ice candidate');
      // Note we name each ice field as the IE/Safari plugin doesn't reflect these for json serialization
      this.sendRtcEvent('ice', {
        complete: false,
        candidate: event.candidate.candidate,
        sdpMid: event.candidate.sdpMid,
        sdpMLineIndex: event.candidate.sdpMLineIndex,
      });
    } else {
      this.log('end ice candidate');
      // all ice candidates have been gathered, send an end of ice notification
      this.sendRtcEvent('ice', {
        complete: true,
        candidate: '',
        sdpMid: '',
        sdpMLineIndex: 0,
      });
    }
  }

  private createdDescription(
    description: RTCSessionDescriptionInit,
    messageName = 'offer'
  ) {
    this.log('got description');

    // Note we name each ice field as the IE/Safari plugin doesn't  reflect these for json serialization
    const descriptionObj = { sdp: description.sdp, type: description.type };
    this.log(JSON.stringify({ sdp: descriptionObj }));

    this.setLocalDescription(this._peerConnection, description)
      .then(() => {
        // Note with the sdp offer information we also send the
        // current video element width/height to remote end when
        // setting up webrtc session so that it can send the best
        // width/height
        this.log('send sdp offer to server');

        // Note we name each sdp field as the IE/Safari plugin doesn't  reflect these for json serialization
        this.sendRtcEvent(messageName, {
          sdp: this._peerConnection.localDescription
            ? this._peerConnection.localDescription.sdp
            : null,
          type: this._peerConnection.localDescription
            ? this._peerConnection.localDescription.type
            : null,
          user_text: this._connectUserText,
          features: { videoStartedEvent: true },
        });
      })
      .catch(this._sessionError.bind(this));
  }

  private onRemoteStream(stream: MediaStream | null) {
    this.log('got remote stream');
    this._remoteStream = stream;

    this.log(
      `ICE connection state: ${this._peerConnection.iceConnectionState}`
    );
    if (this._connectPendingRemoteStream) {
      // A connect has been received however it has been paused while
      // we waited for the remote stream - continue it now
      this._connectPendingRemoteStream();
      this._connectPendingRemoteStream = null;
    }
  }

  private copyStyleFromVideoElementToCanvas() {
    if (this._chromaKeyedCanvas && this._videoElement) {
      const videoElementStyles = getComputedStyle(this._videoElement);
      let cssText = videoElementStyles.cssText;
      if (!cssText) {
        cssText = Array.from(videoElementStyles).reduce(
          (cssString: string, property: string) => {
            let propertyValue: string;
            if (property === 'display') {
              // don't copy display:none style because video element is hidden on purpose
              propertyValue = videoElementStyles
                .getPropertyValue(property)
                .replace('none', '');
            } else {
              propertyValue = videoElementStyles.getPropertyValue(property);
            }
            return `${cssString}${property}:${propertyValue};`;
          },
          ''
        );
        this._chromaKeyedCanvas.style.cssText = cssText;
      }
    }
  }

  private observeAndCopyStyleChangesFromVideoElementToCanvas() {
    if (!this._videoElementStyleObserver) {
      this._videoElementStyleObserver = new MutationObserver(
        (mutationList, observer) => {
          this.copyStyleFromVideoElementToCanvas();
        }
      );
    }

    this._videoElementStyleObserver.observe(this._videoElement, {
      attributes: true,
    });
    this._videoElement.addEventListener(
      'resize',
      this.copyStyleFromVideoElementToCanvas.bind(this)
    );
  }

  private processFrameForChromaKey(now: DOMHighResTimeStamp, metadata: any) {
    if (this._chromaKeyedCanvas) {
      this._chromaKeyedCanvas.width = metadata.width;
      this._chromaKeyedCanvas.height = metadata.height;

      render_rgb(
        this._chromaKeyedCanvas.width,
        this._chromaKeyedCanvas.height,
        this._videoElement
      );
      this._videoElement.requestVideoFrameCallback(
        this.processFrameForChromaKey.bind(this)
      );
    }
  }

  private onConnectedSuccess() {
    this._onConnected(
      this._resumeRequested,
      this._isResumedSession,
      this._server,
      this.sessionId
    );

    if (this._chromaKeyOptions?.enabled && this._videoElement) {
      this.setupChromaKeyedVideo();
    } else if (this._videoElement) {
      this._videoElement.srcObject = this._remoteStream;
      this._videoElement.hidden = false;
    }

    const callback = async (event: RTCTrackEvent) => {
      if (this._remoteStream) {
        this._remoteStream.addTrack(event.track);
      }
    };
    this._peerConnection.addEventListener('track', callback);
    this._removeListeners.push({
      target: this._peerConnection,
      name: 'track',
      callback: callback,
    });

    this.log('video enabled');

    // Update the server of the current camera active state
    this.sendUserCamera();

    // Update the application of the current microphone & camera state
    this._onMicrophoneActive?.call(this.isMicrophoneActive());
    this._onCameraActive?.call(this.isCameraActive());
  }

  private hideVideoElement(videoElement: HTMLVideoElement) {
    videoElement.hidden = true;
    videoElement.style.display = 'none';
  }

  private setupChromaKeyedVideo() {
    if (this._videoElement && this._chromaKeyOptions?.enabled) {
      if (this.supportsInsertableStreams && this.supportsTransferableStreams) {
        // For browsers that support insertable streams and transferable streams
        // Chrome
        this.setupTransferableStreamChromaKeyedVideo();
      } else {
        // For browsers that do not support insertable streams and transferable streams
        this.setupChromaKeyedCanvas(this._chromaKeyOptions);

        this._videoElement.srcObject = this._remoteStream;

        if ('requestVideoFrameCallback' in HTMLVideoElement.prototype) {
          // For browsers that support VideoElement::requestVideoFrameCallback()
          // Firefox
          this.setupRequestVideoFrameCallbackChromaKeyedVideo();
        } else {
          // For browsers that do not support VideoElement::requestVideoFrameCallback()
          // Safari
          this.setupRequestAnimationFrameChromaKeyedVideo();
        }
      }
    }
  }

  private setupChromaKeyedCanvas(chromaKeyOptions: ChromaKeyOptions) {
    this._chromaKeyedCanvas = document.createElement(
      'canvas'
    ) as HTMLCanvasElement;
    this.copyStyleFromVideoElementToCanvas();
    this._videoElement.parentElement?.appendChild(this._chromaKeyedCanvas);
    this.hideVideoElement(this._videoElement);
    this.observeAndCopyStyleChangesFromVideoElementToCanvas();

    updateChromaKeyOptions(chromaKeyOptions);
    initWebGL_rgb_with_canvas(this._chromaKeyedCanvas);
  }

  private setupTransferableStreamChromaKeyedVideo() {
    const worker = new Worker('/dedicated-workers/chroma-key.worker.js', {
      type: 'module',
    });

    worker.postMessage({ chromaKeyOptions: this._chromaKeyOptions });

    if ((window as any).RTCRtpScriptTransform) {
      // No use of script transform until WebCodecs api is further along
      const transceiver = this._peerConnection.getTransceivers()[0];
      (transceiver.receiver as any).transform = new (
        window as any
      ).RTCRtpScriptTransform(worker, { name: 'receiverTransform' });
    } else {
      const videoTrack = this.getTrackByKind(
        this._remoteStream,
        'video'
      ) as MediaStreamVideoTrack;

      const audioTrack = this.getTrackByKind(
        this._remoteStream,
        'audio'
      ) as MediaStreamAudioTrack;

      const processor = new MediaStreamTrackProcessor({
        track: videoTrack,
      });
      const { readable } = processor;

      const generator = new MediaStreamTrackGenerator({ kind: 'video' });
      const { writable } = generator;

      const mediaStream = new MediaStream([generator]);
      mediaStream.addTrack(audioTrack);

      if (this._videoElement) {
        this._videoElement.srcObject = mediaStream;
        this._videoElement.hidden = false;
      } else {
        this.videoMediaStream = mediaStream;
        this.sendRtcEvent('videoStarted', {});
      }

      worker.postMessage(
        {
          readable,
          writable,
        },
        [readable as any, writable]
      );
    }
  }

  private setupRequestVideoFrameCallbackChromaKeyedVideo() {
    this._videoElement.requestVideoFrameCallback(
      this.processFrameForChromaKey.bind(this)
    );
  }

  private setupRequestAnimationFrameChromaKeyedVideo() {
    const update = () => {
      if (this._chromaKeyedCanvas) {
        this._chromaKeyedCanvas.width = this._videoElement.videoWidth;
        this._chromaKeyedCanvas.height = this._videoElement.videoHeight;

        render_rgb(
          this._chromaKeyedCanvas.width,
          this._chromaKeyedCanvas.height,
          this._videoElement
        );
        setTimeout(() => {
          requestAnimationFrame(update);
        }, 1000 / 30); // limit to 30fps because our webrtc stream is about 30fps
      }
    };
    requestAnimationFrame(update);
  }

  private onVideoLoaded(e: Event) {
    this.log('video has loaded');

    const videoStarted = () => {
      this.log('video has started playing');
      this.sendRtcEvent('videoStarted', {});
      this.microphoneMuted = false; // allow the user to speak now that the DP is visible
    };

    // If the video element isn't muted the videoStarted event can be sent immediately
    if (!this._videoElement.muted) {
      videoStarted();
      return;
    }

    // The video element is muted, wait for it to be unmuted before declaring the video fully started
    // there isn't an event for unmuted however volumechange apparently provides a
    // fair alternative: https://stackoverflow.com/questions/25105414/html5-video-onmuted-and-onloop-event
    const unmuteCallback = () => {
      videoStarted();
      this._videoElement.removeEventListener('volumechange', unmuteCallback);
    };

    this._videoElement.addEventListener('volumechange', unmuteCallback, false);
  }

  public sendRtcEvent(name: string, body: any) {
    if (this._serverConnection === null) {
      return;
    }

    if (this._sessionId) {
      body.sessionId = this._sessionId;
    }

    const payload = { category: 'webrtc', kind: 'event', name, body };

    if (this._sessionId || name === 'offer') {
      this.sendMessage(payload);
    } else {
      // The session has not yet been accepted, queue the message until it is
      this._outgoingQueue.push(payload);
    }
  }

  public sendVideoBounds(width: number, height: number) {
    this.sendRtcEvent('videoBounds', { width, height });
  }

  /**
   * Sends updated user camera rotation to server
   * this gives the app the chance to choose the required rotation of the user camera
   * such that it matches the devices orientation.  Values can be 0, 90, 180, 270.
   * @param rotation - The clockwise rotation in degrees of the user video feed (0, 90, 180 or 270)
   * @internal
   */
  private sendUserCamera(rotation?: number) {
    const body = { active: this.isCameraActive() } as Record<string, any>;
    if (rotation !== undefined) {
      body.rotation = rotation;
    }
    this.sendRtcEvent('userCamera', body);
  }

  private sendCameraRotation() {
    if (this._features.isIos) {
      const orientation = window.orientation;
      this.log(
        `send updated camera rotation, device orientation: ${orientation}`
      );

      // Compute the camera orientation for iOS, degrees to rotate the image to the right
      // to give it a correct orientation relative to how the iOS device is held
      let rotateCamera = 0;

      // NB: iOS safari fixedCameraOrientation = -90; - iOS front camera orientation in terms of window orientation positions
      if (orientation === 0) {
        rotateCamera = 90;
      } else if (orientation === 90) {
        rotateCamera = 180;
      } else if (orientation === 180) {
        rotateCamera = 270;
      } else if (orientation === -90) {
        rotateCamera = 0;
      }

      this.sendUserCamera(rotateCamera);
    }
  }

  public sendMessage(message: any): void {
    if (!this._serverConnection) {
      return;
    }

    if (this._serverConnection.readyState === WebSocket.OPEN) {
      // connected
      this._serverConnection.send(JSON.stringify(message));
    } else {
      this.log(`server connection not ready, discarding message: ${message}`);
    }
  }

  public sendUserText(text: string) {
    this.sendRtcEvent('userText', { userText: text });
  }

  private hasCamera(userMedia: UserMedia): boolean {
    return (
      userMedia === UserMedia.Camera ||
      userMedia === UserMedia.MicrophoneAndCamera
    );
  }

  private hasMicrophone(userMedia: UserMedia): boolean {
    return (
      userMedia === UserMedia.Microphone ||
      userMedia === UserMedia.MicrophoneAndCamera
    );
  }

  private makeUserMedia(microphone: boolean, webcam: boolean): UserMedia {
    if (microphone && webcam) {
      return UserMedia.MicrophoneAndCamera;
    } else if (microphone) {
      return UserMedia.Microphone;
    } else if (webcam) {
      return UserMedia.Camera;
    }
    return UserMedia.None;
  }

  private findSenderTrackByKind(kind: string): MediaStreamTrack | null {
    if (!this._peerConnection) {
      return null;
    }

    const senders = this._peerConnection.getSenders();
    for (const sender of senders) {
      if (sender.track && sender.track?.kind === kind) {
        return sender.track;
      }
    }
    return null;
  }

  private findSenderByKind(kind: string): RTCRtpSender | null {
    if (!this._peerConnection) {
      return null;
    }

    for (const transceiver of this._peerConnection.getTransceivers()) {
      if (
        transceiver.direction === 'sendrecv' &&
        transceiver.receiver?.track?.kind === kind
      ) {
        return transceiver.sender;
      }
    }

    for (const sender of this._peerConnection.getSenders()) {
      if (sender.track === null || sender.track.kind === kind) {
        return sender;
      }
    }

    return null;
  }

  private async processChangeUserMediaQueue() {
    let operation: ChangeUserMediaOp | undefined;

    do {
      operation =
        this._changeUserMediaQueue.length > 0
          ? this._changeUserMediaQueue[0]
          : undefined;
      if (operation) {
        try {
          const lastMicrophoneActive = this.isMicrophoneActive();
          const lastCameraActive = this.isCameraActive();

          // Change the media to that requested by the operation
          await this.changeUserMediaInternal(
            this.makeUserMedia(
              operation.microphone ?? lastMicrophoneActive,
              operation.camera ?? lastCameraActive
            )
          );

          // Notify of any change of microphone state
          if (
            operation.microphone !== undefined &&
            operation.microphone !== lastMicrophoneActive
          ) {
            this._onMicrophoneActive?.call(this.isMicrophoneActive());
          }

          // Notify of any change of camera state
          if (
            operation.camera !== undefined &&
            operation.camera !== lastCameraActive
          ) {
            this._onCameraActive?.call(this.isCameraActive());
          }

          // The operation has completed successfully
          operation.deferred.resolve();
        } catch (e) {
          operation.deferred.reject(e);
        }

        // Remove the operation as it's now finished
        this._changeUserMediaQueue.shift();
      }
    } while (operation);
  }

  private async changeUserMediaInternal(
    newUserMedia: UserMedia
  ): Promise<void> {
    const microphoneTrack = this.findSenderTrackByKind('audio');
    const cameraTrack = this.findSenderTrackByKind('video');

    // Find out if we need to upgrade the user media first (i.e. request a new microphone and/or camera track)
    const needMicrophoneUpgrade =
      this.hasMicrophone(newUserMedia) &&
      (!microphoneTrack || microphoneTrack.readyState === 'ended');
    const needCameraUpgrade =
      this.hasCamera(newUserMedia) &&
      (!cameraTrack || cameraTrack.readyState === 'ended');

    let newMediaStream: MediaStream | null = null;

    if (needMicrophoneUpgrade || needCameraUpgrade) {
      // Either the microphone or camera is needed and not yet present,
      // ensure we can get the required user media first
      const requiredMedia = this.makeUserMedia(
        needMicrophoneUpgrade,
        needCameraUpgrade
      );
      const mediaDeferred = new Deferred<any>();

      this.selectUserMedia(
        requiredMedia,
        requiredMedia,
        mediaDeferred,
        async (stream, deferred) => {
          newMediaStream = stream;
          deferred.resolve();
        }
      );

      await mediaDeferred.promise;

      if (!this._localStream) {
        this._localStream = new MediaStream();
      }
    }

    // Update the microphone track
    await this.updateSenderTrack(
      'audio',
      this.hasMicrophone(newUserMedia),
      newMediaStream
    );

    // Update the camera track
    await this.updateSenderTrack(
      'video',
      this.hasCamera(newUserMedia),
      newMediaStream
    );

    // Update the server of the current camera active state
    this.sendUserCamera();
  }

  // Update the RTP sender / track to the given active state. If active then the newMediaStream must
  // contain a track for the requested 'kind'
  private async updateSenderTrack(
    kind: 'audio' | 'video',
    active: boolean,
    newMediaStream: MediaStream | null
  ): Promise<void> {
    const sender = this.findSenderByKind(kind);
    const track = sender?.track;

    // Update the user media track
    if (!!sender && (!track || active !== track.enabled)) {
      this.log('new user ' + kind + ' active state = ' + active);
      if (active) {
        try {
          if (track) {
            this._localStream?.removeTrack(track);
          }
          if (newMediaStream) {
            const newTrack = this.getTrackByKind(newMediaStream, kind);
            if (newTrack) {
              this._localStream?.addTrack(newTrack);
              if (sender.track !== newTrack) {
                this.log('replacing user ' + kind + ' track');
                await sender.replaceTrack(newTrack);
              }
            }
          }
        } catch (e) {
          this.log(`failed to get user ${kind} track, error: ${e}`, 'error');
          throw makeError(
            'failed to get user ' + kind + ': ' + e,
            'failedUpgrade'
          );
        }
      } else if (track) {
        track.enabled = false;
        track.stop();
      }
    }
  }

  private getTrackByKind(
    stream: MediaStream | null,
    kind: string
  ): MediaStreamTrack | undefined {
    if (stream) {
      for (const track of stream.getTracks()) {
        if (track.kind === kind) {
          return track;
        }
      }
    }
    return undefined;
  }

  private isSenderTrackEnabled(kind: 'audio' | 'video'): boolean {
    const track = this.findSenderTrackByKind(kind);
    return Boolean(track?.enabled);
  }

  isMicrophoneActive(): boolean {
    return this.isSenderTrackEnabled('audio');
  }

  isCameraActive(): boolean {
    return this.isSenderTrackEnabled('video');
  }

  async setMediaDeviceActive({
    microphone,
    camera,
  }: {
    microphone?: boolean;
    camera?: boolean;
  }): Promise<void> {
    // Queue changes to media so that we execute them one at a time to completion,
    // this is particularly important when negotiating with the server to
    // liven the new RTP sender
    const deferred = new Deferred<any>();
    this._changeUserMediaQueue.push({ microphone, camera, deferred });

    if (this._changeUserMediaQueue.length === 1) {
      this.processChangeUserMediaQueue();
    }

    return deferred.promise;
  }

  public close(
    sendRtcClose = true,
    reason = 'normal',
    deferred?: Deferred<any>
  ) {
    if (this._closed) {
      return;
    }

    this._closed = true;

    if (this._peerConnection) {
      try {
        this._peerConnection.close();
      } catch (e) {
        this.log(`error: ${e}`, 'error');
      }
    }

    if (this._localStream) {
      try {
        const tracks = this._localStream.getTracks();
        for (const i in tracks) {
          tracks[i].stop();
        }
      } catch (e) {
        this.log(`error: ${e}`, 'error');
      }
    }

    if (sendRtcClose) {
      this.sendRtcEvent('close', { reason: 'normal' });
    }

    if (deferred) {
      if (deferred.isResolved()) {
        this._onClose(reason);
      } else {
        deferred.reject(makeError('websocket closed: ' + reason, reason));
      }
    }

    if (this._serverConnection) {
      this.log('closing server connection');
      this._serverConnection.close();
    }

    if (this._controlConnection) {
      this._controlConnection.close();
    }

    // Deregister event listeners
    for (const listener of this._removeListeners) {
      listener.target.removeEventListener(listener.name, listener.callback);
    }
  }

  private createOffer(
    peerConnection: RTCPeerConnection,
    options: RTCOfferOptions
  ) {
    return peerConnection.createOffer(options);
  }

  private setRemoteDescription(
    peerConnection: RTCPeerConnection,
    sessionDescription: RTCSessionDescriptionInit
  ) {
    return peerConnection.setRemoteDescription(sessionDescription);
  }

  private setLocalDescription(
    peerConnection: RTCPeerConnection,
    description: RTCSessionDescriptionInit
  ) {
    return peerConnection.setLocalDescription(description);
  }

  private createAnswer(peerConnection: RTCPeerConnection) {
    return peerConnection.createAnswer();
  }

  get peerConnection(): RTCPeerConnection | null {
    return this._peerConnection;
  }

  get serverConnection(): WebSocket {
    return this._serverConnection;
  }

  get sessionId(): string {
    return this._sessionId;
  }

  get server(): string {
    return this._server;
  }

  get sceneId(): number {
    return this._sceneId;
  }

  get isMicrophoneConnected(): boolean {
    return !!this.findSenderTrackByKind('audio');
  }

  get isCameraConnected(): boolean {
    return !!this.findSenderTrackByKind('video');
  }

  get features(): Features {
    return this._features;
  }

  get microphoneMuteDelay(): number {
    return this._microphoneMuteDelay;
  }

  get userMediaStream(): MediaStream | null {
    return this._localStream;
  }

  get microphoneMuted(): boolean {
    if (!this._localStream) {
      return true;
    }
    const audioTracks = this._localStream.getAudioTracks();
    if (!audioTracks || audioTracks.length < 1) {
      return true;
    }

    return !audioTracks[0].enabled;
  }

  set microphoneMuted(mute: boolean) {
    if (!this._localStream) {
      return;
    }
    const audioTracks = this._localStream.getAudioTracks();
    if (!audioTracks || audioTracks.length < 1) {
      return;
    }
    const audioSender = this.findSenderByKind('audio');
    if (
      audioSender?.track?.readyState === 'live' &&
      audioSender.track === audioTracks[0]
    ) {
      const enable = !mute;
      audioTracks[0].enabled = enable;
      this.log(`microphone muted active notification: ${enable}`);
      this._onMicrophoneActive?.call(enable);
    }
  }

  get webcamMuted(): boolean {
    if (!this._localStream) {
      return true;
    }
    const videoTracks = this._localStream.getVideoTracks();
    if (!videoTracks || videoTracks.length < 1) {
      return true;
    }

    return !videoTracks[0].enabled;
  }

  set webcamMuted(mute: boolean) {
    if (!this._localStream) {
      return;
    }
    const videoTracks = this._localStream.getVideoTracks();
    if (!videoTracks || videoTracks.length < 1) {
      return;
    }

    const videoSender = this.findSenderByKind('video');
    if (
      videoSender?.track?.readyState === 'live' &&
      videoSender.track === videoTracks[0]
    ) {
      const enable = !mute;
      videoTracks[0].enabled = enable;
      this._onCameraActive?.call(enable);
    }
  }

  get offsetX(): number {
    return 0;
  }

  get offsetY(): number {
    return 0;
  }

  set microphoneActiveCallbacks(callbacks: SmEvent) {
    this._onMicrophoneActive = callbacks;
  }

  set cameraActiveCallbacks(callbacks: SmEvent) {
    this._onCameraActive = callbacks;
  }

  /**
   * Checks if the browser supports WebRTC Encoded Transform, an alternative
   * to insertable streams.
   * @returns {boolean} {@code true} if the browser supports it.
   */
  get supportsEncodedTransform(): boolean {
    return Boolean((window as any).RTCRtpScriptTransform);
  }

  /**
   * Checks if the browser supports insertable streams.
   * @returns {boolean} {@code true} if the browser supports insertable streams.
   */
  get supportsInsertableStreams() {
    if (
      !(
        typeof window.RTCRtpSender !== 'undefined' &&
        (window.RTCRtpSender.prototype as any).createEncodedStreams
      )
    ) {
      return false;
    }
    return true;
  }

  /**
   * Checks if the browser supports transferable streams.
   * @returns {boolean} {@code true} if the browser supports transferable streams.
   */
  get supportsTransferableStreams() {
    // Feature-detect transferable streams which we need to operate in a worker.
    // See https://groups.google.com/a/chromium.org/g/blink-dev/c/1LStSgBt6AM/m/hj0odB8pCAAJ
    const stream: any = new ReadableStream();

    try {
      window.postMessage(stream, '*', [stream]);
      return true;
    } catch {
      return false;
    }
  }
}
