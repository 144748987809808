/**
 * @module smwebsdk
 */

/*
 * Copyright 2017-2020 Soul Machines Ltd. All Rights Reserved.
 */

import { Persona } from './Persona';
import { SmEvent } from './SmEvent';
import { LocalSession } from './LocalSession';
import { Session } from './Session';
import { WebSocketSession } from './WebSocketSession';
import {
  WebsocketResponse,
  WebsocketCategory,
  WebsocketKind,
} from './websocket-message/index';
import { SpeechState } from './websocket-message/enums/SpeechState';
import {
  SceneRequest,
  SceneRequestBody,
  StartRecognizeRequestBody,
  SceneResponse,
  RecognizeResultsResponseBody,
  DemoModeResponseBody,
  SceneResponseError,
  ConversationResultResponseBody,
  SpeechMarkerResponseBody,
  StateResponseBody,
} from './websocket-message/scene/index';
import { AudioSourceTypes } from './enums/index';
import {
  ConfigurationModel,
  PersonaEventMap,
  PendingPromise,
} from './models/index';
import { SceneResponseBody } from './websocket-message/scene/SceneResponse';
import { FeatureFlag } from './websocket-message/scene/response-body/StateResponseBody';
import { ContentAwareness } from './ContentAwareness';
import { Logger, LogLevel } from './utils/Logger';
import { Context, ROOT_CONTEXT, Span } from '@opentelemetry/api';
import { makeError } from './utils/make-error';
import convertToUserMedia from './utils/convertToUserMedia';
import { convertWssToHttps, getUrlHost } from './utils/utils';
import {
  LoggingConfig,
  MediaDeviceOptions,
  RetryOptions,
  UserMedia,
} from './types/scene';
import { Conversation } from './Conversation';
import { MetadataSender } from './MetadataSender';
import { PersonaId } from './models/PersonaId';
import { ConnectionState } from './ConnectionState';
import { SmTracerProvider } from './SmTelemetry';
import { websdkVersion } from './env-vars';
import { ChromaKeyOptions } from './dedicated-workers/chroma-key.worker';

function sleep(t: number): Promise<void> {
  return new Promise((resolve) => setTimeout(() => resolve(), t));
}

const DEFAULT_RETRY_COUNT = 50;
const DEFAULT_RETRY_DELAY = 200;
const DEFAULT_PERSONA_ID = 1;

/**
 * Configuration to use when constructing a Scene
 *
 * @public
 */
export interface SceneOptions {
  /** The HTMLVideoElement where the digital person should be displayed */
  videoElement?: HTMLVideoElement;
  /** Whether the connection should be established with audio but no video */
  audioOnly?: boolean;
  /** Default true. True means that the DP's speech should be interrupted when the user changes tabs */
  stopSpeakingWhenNotVisible?: boolean;
  /** Media devices (camera and microphone) to request from the user before connecting */
  requestedMediaDevices?: MediaDeviceOptions;
  /** Media devices (camera and microphone) that the user must approve access to for the connection to succeed */
  requiredMediaDevices?: MediaDeviceOptions;
  /** Frequency of sending on-screen content measurements to the server in milliseconds */
  contentAwarenessDebounceTime?: number;
  /** Preferred logging levels for websdk */
  loggingConfig?: LoggingConfig;
  /** The API key used to authenticate with the server */
  apiKey?: string;
  /** The config to allow sending current page url when connection succeeds */
  sendMetadata?: {
    pageUrl: boolean;
  };
  /** Configuration of the OpenTelemetry tracer */
  tracerOptions?: TracerOptions;
  /** Chroma key options
   * @internal
   */
  chromaKeyOptions?: ChromaKeyOptions;
}

/**
 * Configuration to use when connecting to a Scene
 *
 * @public
 */
export interface ConnectOptions {
  /** Options for customizing connection error retries */
  retryOptions?: RetryOptions;
  /** A custom text string that is sent to the orchestration server */
  userText?: string;
  /** The custom token server config */
  tokenServer?: {
    /** The server websocket uri  */
    uri: string;
    /** A jwt access token issued to permit access to the server */
    token: string;
  };
}

async function retry(
  task: () => Promise<any>,
  retryOptions: RetryOptions = {},
  scene: Scene
) {
  const errors = [];
  const count = retryOptions.maxRetries || DEFAULT_RETRY_COUNT;
  const delay = retryOptions.delayMs || DEFAULT_RETRY_DELAY;
  let result: Promise<any> | undefined;
  for (let i = 0; i < count; i++) {
    try {
      result = await task();

      // store the result on the scene object
      scene.connectionResult = {
        message: 'success',
        value: result,
        retries: errors,
      };
    } catch (error: unknown) {
      // collect a history of errors encountered during connect
      errors.push(error);

      // store the result on the scene object
      scene.connectionResult = {
        message: 'failed',
        retries: errors,
      };

      //if error is 'noResumeSession` should cleanup session storage
      if (error instanceof Error && error.name === 'noSessionToResume') {
        clearSessionData();
      }

      // any error other than 'noScene' should throw immediately
      // and should not retry repeatedly.
      // allows for proper errors and also string errors
      if (!(error instanceof Error) || error.name !== 'noScene') {
        throw error;
      }

      // when we have reached the max number of retries,
      // we should give up and throw the error
      if (errors.length === count) {
        console.warn(
          `Retry gave up after ${count} retries:\n${errors
            .map((e) =>
              e instanceof Error ? e.message : (e as string).toString()
            )
            .join('\n')}`
        );

        // throw the most recent error as the primary cause of failure
        throw error;
      }

      await sleep(delay);
      continue;
    }
    break;
  }
  return result;
}

function storeSessionData(server: string, sessionId: string, apiKey: string) {
  sessionStorage.setItem('sm-server', server);
  sessionStorage.setItem('sm-session-id', sessionId);
  sessionStorage.setItem('sm-api-key', apiKey);
}

function getSessionData() {
  return {
    server: sessionStorage.getItem('sm-server'),
    resumeSessionId: sessionStorage.getItem('sm-session-id'),
    savedApiKey: sessionStorage.getItem('sm-api-key'),
  };
}

function clearSessionData() {
  sessionStorage.removeItem('sm-server');
  sessionStorage.removeItem('sm-session-id');
  sessionStorage.removeItem('sm-api-key');
}

/**
 * Available configuration options for the `Scene.connect` tracer.
 */
export type TracerOptions = {
  /** Suppress tracing by OpenTelemetry. */
  disableTracing: boolean;
  /** The context to use as the parent for tracing. */
  parentCtx: Context;
  /** The endpoint to use for sending traces */
  url: string;
};

/**
 * Scene class to hold a webrtc connection to a scene containing a persona.
 * @public
 */
export class Scene {
  private _apiKey: string | undefined;
  private _videoElement: HTMLVideoElement | undefined;
  private _audioOnly: boolean;
  private _requestedUserMedia: UserMedia;
  private _requiredUserMedia: UserMedia;
  /**
   * set to function(scene, state) called when a state message is received as per the scene protocol
   * (DEPRECATED: onStateEvent.addListener allows for multiple listeners).
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  private _onState: Function | undefined; // function (scene, state)
  private _onStateEvent: SmEvent;

  /**
   * set to function(scene, results) called when speech to text results are recognized,
   * results are documented in scene protocol
   * (DEPRECATED: onRecognizeResultsEvent.addListener allows for multiple listeners).
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  private _onRecognizeResults: Function | undefined; // function (scene, results)
  private _onRecognizeResultsEvent: SmEvent;

  private _metadataSender: MetadataSender;

  /**
   * set to function(scene, sessionId, reason) called when the session is disconnected.
   * 'reason' can be one of 'normal' or 'sessionTimeout'
   * (DEPRECATED: onDisconnectedEvent.addListener allows for multiple listeners).
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  private _onDisconnected: Function | undefined; // function (scene, sessionId, reason)
  private _onDisconnectedEvent: SmEvent;

  /** set to function(scene, text) called when a custom text message is sent from the orchestration server */
  // eslint-disable-next-line @typescript-eslint/ban-types
  private _onUserText: Function | undefined; // function (scene, text)
  private _onUserTextEvent: SmEvent;

  /** Demo mode events */
  // eslint-disable-next-line @typescript-eslint/ban-types
  private _onDemoMode: Function | undefined; // function (scene, params)
  private _onDemoModeEvent: SmEvent;

  private _onConversationResultEvents: PersonaEventMap = {}; // persona id -> SmEvent with function(persona, result)
  private _onSpeechMarkerEvents: PersonaEventMap = {}; // persona id -> SmEvent function(persona, marker)

  private _session: Session | LocalSession | WebSocketSession | undefined =
    undefined;

  private _underRuntimeHost: boolean;
  private _isWebSocketOnly = false;

  private _transactionId = 0;
  private _pendingResponses: { [index: string]: PendingPromise } = {};

  private _sceneId: string;

  private _microphoneUnmuteTimer: any = undefined;

  private _echoCancellationEnabled = true;

  private _serverControlledCameras = false;

  private _stopSpeakingWhenNotVisible = true;

  private _loggingConfig: LoggingConfig = {
    session: {},
    contentAwareness: {},
  };

  private _logger: Logger = new Logger();

  private _tracerOptions: TracerOptions = {
    disableTracing: false,
    parentCtx: ROOT_CONTEXT,
    url: SmTracerProvider.defaultUrl,
  };

  private _chromaKeyOptions: ChromaKeyOptions | undefined = undefined;

  public connectionResult: any;

  public contentAwareness: ContentAwareness | undefined;
  public contentAwarenessDebounceTime: number | undefined;
  private _sessionResumeEnabled = false;
  private _isResumedSession = false;
  private _sendMetadata = { pageUrl: false };

  private _onMicrophoneActive = new SmEvent();
  private _onCameraActive = new SmEvent();

  public conversation: Conversation;
  public connectionState: ConnectionState;
  public currentPersonaId: PersonaId = DEFAULT_PERSONA_ID;
  /** Returns the version of the webSdk and platformSdk */
  public version = {
    webSdk: websdkVersion,
    platformSdk: 'unknown',
  };

  /**
   * Tests the first value of the Scene construtor to decide if
   * it matches the new-style config options format.
   */
  private isSceneOptions(
    videoOrOptions?: HTMLVideoElement | SceneOptions
  ): videoOrOptions is SceneOptions {
    // scene options must be defined, even if they're an empty object
    const isDefined = !!videoOrOptions;
    // scene options object will not have a tagName
    const isHTMLElement = !!(videoOrOptions as HTMLVideoElement)?.tagName;

    return isDefined && !isHTMLElement;
  }

  /**
   * Construct Scene from options object
   * @param options - {@link SceneOptions} Optional configuration for the Scene. May be an empty object.
   */
  constructor(options: SceneOptions);
  /**
   * Construct Scene with parameters
   * @deprecated Use `new Scene(options: SceneOptions)` instead
   * @param videoElement - A video element that will display the connected scene
   * @param audioOnly - This streaming should be audio streaming only (no video streaming)
   * @param requestedUserMedia - The user media devices (microphone/camera) that should be requested, one of:
   *     UserMedia.None, UserMedia.Microphone, UserMedia.MicrophoneAndCamera (default)
   * @param requiredUserMedia - Required user media devices, one of:
   *     UserMedia.None, UserMedia.Microphone, UserMedia.MicrophoneAndCamera
   *     If less user media devices are requested then are required then the requirements takes precedence.
   *     If this user media requirements is not met then Connect() will fail.
   * @param contentAwarenessDebounceTime - The timeout period used for debouncing messaging within the content awareness class
   * @param loggingConfig - Options to configure different log levels for different classes
   */
  constructor(
    videoElement?: HTMLVideoElement,
    audioOnly?: boolean,
    requestedUserMedia?: UserMedia,
    requiredUserMedia?: UserMedia,
    contentAwarenessDebounceTime?: number,
    loggingConfig?: Partial<LoggingConfig>,
    tracerOptions?: TracerOptions,
    chromaKeyOptions?: ChromaKeyOptions
  );
  constructor(
    videoOrOptions?: HTMLVideoElement | SceneOptions,
    audioOnly = false,
    requestedUserMedia = UserMedia.MicrophoneAndCamera,
    requiredUserMedia = UserMedia.Microphone,
    contentAwarenessDebounceTime?: number,
    loggingConfig?: Partial<LoggingConfig>,
    tracerOptions?: TracerOptions,
    chromaKeyOptions?: ChromaKeyOptions
  ) {
    // use the first parameter of the constructor to figure out
    // whether it was constructed using SceneOptions, or using
    // the deprecated multi-param format.
    if (this.isSceneOptions(videoOrOptions)) {
      // pull all private property initial values from the config object,
      // with fallbacks to the constructor property defaults if not provided
      const options = videoOrOptions;
      this._videoElement = options.videoElement;
      this._apiKey = options.apiKey;
      this._audioOnly = options.audioOnly || audioOnly;

      // default is "true" so can't use a shorthand falsy assessment to read this config option
      if (options.stopSpeakingWhenNotVisible === false) {
        this._stopSpeakingWhenNotVisible = false;
      }

      this._requestedUserMedia = convertToUserMedia(
        options.requestedMediaDevices,
        requestedUserMedia
      );
      this._requiredUserMedia = convertToUserMedia(
        options.requiredMediaDevices,
        requiredUserMedia
      );
      this.contentAwarenessDebounceTime = options.contentAwarenessDebounceTime;
      this._loggingConfig = {
        ...this._loggingConfig,
        ...(options.loggingConfig || {}),
      };
      if (options.sendMetadata) {
        this._sendMetadata = options.sendMetadata;
      }
      if (options.tracerOptions) {
        this._tracerOptions = options.tracerOptions;
      }
      this._chromaKeyOptions = options.chromaKeyOptions;
    } else {
      // take all private property initial values directly from the constructor props
      this._videoElement = videoOrOptions;
      this._audioOnly = audioOnly;
      this._requestedUserMedia = requestedUserMedia;
      this._requiredUserMedia = requiredUserMedia;
      this.contentAwarenessDebounceTime = contentAwarenessDebounceTime;
      this._loggingConfig = {
        ...this._loggingConfig,
        ...loggingConfig,
      };
      if (tracerOptions) {
        this._tracerOptions = tracerOptions;
      }
      this._chromaKeyOptions = chromaKeyOptions;
    }

    this._logger = new Logger(
      this._loggingConfig.session.minLogLevel,
      this._loggingConfig.session.enabled
    );

    /**
     * call onStateEvent.addListener(function(scene, state)) to be called when a state message is received as per the scene protocol
     * call onStateEvent.removeListener(function(scene, state)) to deregister a listener.
     */
    this._onStateEvent = new SmEvent();
    this._onStateEvent.addListener((scene: Scene, state: StateResponseBody) => {
      if (this._onState) {
        this._onState(scene, state);
      }
    });

    /**
     * call onRecognizeResultsEvent.addListener(function(scene, status, errorMessage, results)) to be called when speech to text results are recognized, results are documented in scene protocol.
     * call onRecognizeResultsEvent.removeListener(function(scene, status, errorMessage, results)) to deregister a listener.
     */
    this._onRecognizeResultsEvent = new SmEvent();
    this._onRecognizeResultsEvent.addListener(
      (
        scene: Scene,
        status: RecognizeResultsResponseBody['status'],
        errorMessage: RecognizeResultsResponseBody['errorMessage'],
        results: RecognizeResultsResponseBody['results']
      ) => {
        if (this._onRecognizeResults) {
          this._onRecognizeResults(scene, status, errorMessage, results);
        }
      }
    );

    /**
     * call onDisconnectedEvent.addListener(function(scene, sessionId, reason)) to be  called when the session is disconnected.
     * call onDisconnectedEvent.removeListener(function(scene, sessionId, reason)) to deregister a listener.
     */
    this._onDisconnectedEvent = new SmEvent();
    this._onDisconnectedEvent.addListener(
      (scene: Scene, sessionId: string, reason: string) => {
        clearSessionData();
        this.cleanupEventListeners();
        if (this._onDisconnected) {
          this._onDisconnected(scene, sessionId, reason);
        }
      }
    );

    /**
     * call onUserTextEvent.addListener(function(scene, text)) to be called when a custom text message is sent from the orchestration server
     * call onUserTextEvent.removeListener(function(scene, text)) to deregister a listener.
     */
    this._onUserTextEvent = new SmEvent();
    this._onUserTextEvent.addListener((scene: Scene, text: string) => {
      if (this._onUserText) {
        this._onUserText(scene, text);
      }
    });

    this._onDemoModeEvent = new SmEvent();

    this._underRuntimeHost = Boolean(window.SmIsUnderRuntimeHost);

    // Generate a random id for the scene. This is used internally with the _transactionId
    // to ensure unique transaction ids when mulitple Scene instances access the same BL instances
    // eg multiple Soul Studio windows
    const randArray = new Uint32Array(3);
    window.crypto.getRandomValues(randArray);
    this._sceneId = randArray.toString().replace(/,/g, '-');

    this.conversation = new Conversation();
    this.connectionState = new ConnectionState();
    this._metadataSender = new MetadataSender(this);

    this._logger.log('debug', 'websdk version:', this.version.webSdk);
  }

  public connectionValid(): boolean {
    if (this._underRuntimeHost) {
      return true;
    }
    if (this._session && this._session.serverConnection) {
      return true;
    }
    return false;
  }

  /**
   * Check if the scene connection is open and valid.
   *
   * @returns Returns true if the connection is open and valid otherwise false.
   */
  public isConnected(): boolean {
    if (
      this.connectionValid() &&
      this._session &&
      this._session.serverConnection &&
      this._session.serverConnection.readyState ===
        this._session.serverConnection.OPEN
    ) {
      return true;
    }
    return false;
  }

  /**
   * Extends the server side timeout. This also happens automatically whenever the persona speaks.
   */
  public keepAlive() {
    if (this._session && this._session.peerConnection !== null) {
      this._session.sendRtcEvent('keepAlive', {});
    }
  }

  /**
   * Disconnects the session
   */
  public disconnect() {
    clearSessionData();
    this.cleanupEventListeners();
    this.connectionState.reset();
    this.conversation.reset();
    this.contentAwareness?.disconnect();
    this._metadataSender.disconnect();
    this._session?.close(true);
    this._session = undefined;
  }

  private iosVisibilityChange = () => {
    const visible = document.visibilityState === 'visible';
    setTimeout(() => {
      if (this._session) {
        this._session.sendRtcEvent('ui', { visible });
      }
    }, 500); // allow 100ms for the H.264 decoder to become fully available again
  };
  /**
   * Connect to a scene using options object
   */
  public async connect(options?: ConnectOptions): Promise<string | undefined>;
  // from sceneKind (e.g. persona selection - rachel/nadia) and process into serverUri if needed, keep connection
  // abstract from person in case we need straight webrtc connection
  /**
   * Connect to a scene at the given server uri.
   *
   * @param serverUri - The server websocket uri to connect to.
   * @param userText - A custom text string that is sent to the orchestration server.
   * @param accessToken - A jwt access token issued to permit access to this server.
   * @param retryOptions - Options for customizing connection error retry.
   * @returns  Returns a promise that holds success/failure callbacks. If the promise
   *                      is rejected then an Eror is given as the argument
   *                      converts to a string message.  The error result has two fields 'message'
   *                      which is the string message and 'name' which is one of the following
   *                      error name/reason codes:
   *    - **notSupported** - the browser does not support getUserMedia
   *    - **noUserMedia** - the microphone/camera is either not available, not usable or the user declined permission to use them
   *    - **serverConnectionFailed** - the connection to the server failed
   *    - **noScene** - no persona was available
   *    - **mediaStreamFailed** - the audio/video stream failed
   *    - **sessionTimeout** - the session timed out before it was fully available
   */
  public async connect(
    serverUri?: string,
    userText?: string,
    accessToken?: string,
    retryOptions?: RetryOptions
  ): Promise<string | undefined>;
  public async connect(
    serverUriOrOptions?: string | ConnectOptions,
    userText?: string,
    accessToken?: string,
    retryOptions?: RetryOptions
  ) {
    const connectStartTime = Date.now();

    const config = this.connectArgsToConfig(
      serverUriOrOptions,
      userText,
      accessToken,
      retryOptions
    );

    let span: Span;
    const recordEventsToSpan = (event: { name: string }) => {
      span?.addEvent(event.name);
    };
    this.connectionState.onConnectionStateUpdated.addListener(
      recordEventsToSpan
    );
    if (this._underRuntimeHost) {
      this._session = new LocalSession(this._videoElement, this._logger);
    } else {
      // Determine if user is passing in auth via the params
      const hasPassedInTokenServerAuth =
        config.tokenServerUri || config.tokenServerAccessToken;

      if (this._apiKey && hasPassedInTokenServerAuth) {
        this._logger.log(
          'warn',
          'You are trying to connect via an API key and a token server. Please use one or the other'
        );
      }

      // If API key is defined and the auth creds have not been passed in
      if (this._apiKey && !hasPassedInTokenServerAuth) {
        try {
          const response = await this.fetchAuthConfig(this._apiKey);
          const data: { url: string; jwt: string } = await response.json();
          const { server } = getSessionData();
          config.tokenServerUri = data.url;
          config.tokenServerAccessToken = data.jwt;
          if (server) {
            config.tokenServerUri = getUrlHost(data.url) + 'server/' + server;
          }
        } catch (error) {
          if (error instanceof Error && error.message === 'Broken API key') {
            this._logger.log(
              'error',
              'Broken API key. Please check your key or re copy the key from DDNA Studio.'
            );
          } else {
            this._logger.log(
              'error',
              'Invalid API key: Please check your key configuration in DDNA Studio. For more information click here https://soulmachines-support.atlassian.net/wiki/spaces/SSAS/pages/1320058919/Connecting+Using+API+Keys#Troubleshooting'
            );
          }
          throw makeError('Invalid API key', 'serverConnectionFailed');
        }
      }

      if (!config.tokenServerUri || !config.tokenServerAccessToken) {
        throw makeError(
          'Please authenticate via an API key or with a serverUri and accessToken',
          'serverConnectionFailed'
        );
      }

      // Initialize the tracer
      const { initTracerStartTime, initTracerEndTime } =
        await this.initializeTracer(
          config.tokenServerUri,
          config.tokenServerAccessToken
        );

      span = SmTracerProvider.getTracer()
        ?.startSpan('createSessionAndConnect')
        ?.setAttribute(
          'sm.websdk.connection.pretraceinitduration.milliseconds',
          initTracerStartTime - connectStartTime
        )
        ?.setAttribute(
          'sm.websdk.connection.traceinitduration.milliseconds',
          initTracerEndTime - initTracerStartTime
        );

      if (this._isWebSocketOnly) {
        this._session = new WebSocketSession(
          config.tokenServerUri,
          config.tokenServerAccessToken,
          this._logger
        );
      } else {
        this._session = new Session(
          this._videoElement as HTMLVideoElement,
          config.tokenServerUri,
          config.userText,
          config.tokenServerAccessToken,
          this._audioOnly,
          this._requestedUserMedia,
          this._requiredUserMedia,
          this._echoCancellationEnabled,
          this._logger,
          this.connectionState,
          this._chromaKeyOptions
        );
      }
    }

    if (!this._session) {
      throw makeError('Failed to create session', 'unknown');
    }

    this._session.onConnected = this.sessionConnected.bind(this);
    this._session.onMessage = this.onMessage.bind(this);
    this._session.onClose = this.sessionClosed.bind(this);
    this._session.onUserText = this.rtcUserText.bind(this);

    if ('microphoneActiveCallbacks' in this._session) {
      this._session.microphoneActiveCallbacks = this._onMicrophoneActive;
    }
    if ('cameraActiveCallbacks' in this._session) {
      this._session.cameraActiveCallbacks = this._onCameraActive;
    }
    if (this._session.features.isIos) {
      document.addEventListener('visibilitychange', this.iosVisibilityChange);
    }

    return await retry(
      async () => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return await this!._session!.connect();
      },
      config.retryOptions,
      this
    ).finally(() => {
      this.connectionState.onConnectionStateUpdated.removeListener(
        recordEventsToSpan
      );
      span?.end();
    });
  }

  private async initializeTracer(
    tokenServerUri: string,
    tokenServerAccessToken: string
  ): Promise<{ initTracerStartTime: number; initTracerEndTime: number }> {
    const initTracerStartTime = Date.now();
    if (
      !this._tracerOptions.disableTracing &&
      !SmTracerProvider.isInitialized()
    ) {
      try {
        await this.initTelemetryToken({
          tokenServerUri,
          authToken: tokenServerAccessToken,
        });
      } catch (error: unknown) {
        const base = 'Could not initialize tracer telemetry token: ';
        if (
          error instanceof ReferenceError &&
          error.message === 'fetch is not defined'
        ) {
          // This happens in a non-browser environment (usually a test
          // environment), so we can't get a telemetry token. Just log to
          // debug.
          this._logger.log('debug', base + error.name + ': ' + error.message);
        } else if (error instanceof Error) {
          this._logger.log('warn', base + error.name + ': ' + error.message);
        } else {
          this._logger.log('warn', base + 'unknown error type');
        }
      }
    }
    return { initTracerStartTime, initTracerEndTime: Date.now() };
  }

  private async initTelemetryToken({
    tokenServerUri,
    authToken,
  }: {
    tokenServerUri: string;
    authToken: string;
  }): Promise<void> {
    const otelJwtEndpoint = 'api/telemetry/jwt';
    const tracesEndpoint = 'api/telemetry/v1/traces';
    const telemetryHost = getUrlHost(convertWssToHttps(tokenServerUri));
    if (!telemetryHost) {
      this._logger.log(
        'debug',
        'Could not initialize tracer telemetry token: invalid token server URI'
      );
      return;
    }

    const resp = await fetch(telemetryHost + otelJwtEndpoint, {
      headers: {
        Authorization: 'Bearer ' + authToken,
      },
    });

    if (!resp) {
      this._logger.log(
        'warn',
        'Failed to receive response from otel token endpoint'
      );
      return;
    }
    if (!resp.ok) {
      this._logger.log(
        'warn',
        'Failed to fetch otel token: ' + resp.status + ': ' + resp.statusText
      );
      return;
    }

    const otelResponse: { success: boolean; telemetryJwt: string } =
      await resp.json();
    if (!otelResponse.success) {
      this._logger.log('warn', 'Failed: otel response not successful');
      return;
    }

    try {
      SmTracerProvider.init({
        jwt: otelResponse.telemetryJwt,
        url: telemetryHost + tracesEndpoint,
        webSDKVersion: this.version.webSdk,
      });
      this._logger.log('log', 'Telemetry initialized');
    } catch (error: unknown) {
      if (error instanceof Error) {
        this._logger.log(
          'warn',
          'Failed to initialize tracer: ' + error.message
        );
      } else {
        this._logger.log('warn', 'Failed to initialize tracer: unknown error');
      }
    }
  }

  public onMessage(message: WebsocketResponse) {
    const { category } = message;

    if (category === 'scene') {
      const sceneMessage = message as SceneResponse;
      this.onSceneMessage(sceneMessage);
      return;
    }
  }

  public sendOnewaySceneRequest(name: string, body: SceneRequestBody) {
    if (!this._session) {
      return;
    }

    const payload: SceneRequest = {
      name,
      body,
      category: WebsocketCategory.Scene,
      kind: WebsocketKind.Request,
    };
    this._session.sendMessage(payload);
  }

  /**
   * The internal method used for sending request messages.
   *
   * All offically supported message have their own public methods (e.g. `conversationSend()` or `scene.startRecognize()`). \
   * Please use those instead.
   *
   * @internal
   */
  public sendRequest(name: string, body: SceneRequestBody = {}): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (!this._session) {
        reject(makeError('No session available', 'noSession'));
        return;
      }

      const transaction = this._sceneId + '_' + ++this._transactionId;
      const payload: SceneRequest = {
        transaction,
        name,
        body,
        category: WebsocketCategory.Scene,
        kind: WebsocketKind.Request,
      };

      const pending: PendingPromise = { resolve, reject };
      this._pendingResponses[transaction] = pending;
      if (this._session) {
        this._session.sendMessage(payload);
      }
    });
  }

  public onSceneMessage(message: SceneResponse) {
    const { name, body, kind, status, transaction } = message;

    // Process events
    if (body && name === 'state') {
      const responseBody = body as StateResponseBody;
      this._onStateEvent.call(this, responseBody);

      if (responseBody.scene?.featureFlags) {
        this.enableFlaggedFeatures(responseBody.scene.featureFlags);
      }

      if (responseBody.scene?.sdkVersion) {
        this.version.platformSdk = responseBody.scene?.sdkVersion;
        this._logger.log(
          'debug',
          'platformSdk version:',
          this.version.platformSdk
        );
      }

      this.conversation.processStateMessage(responseBody);

      // mute the microphone while a persona is speaking
      this.controlMicrophoneMute(body as StateResponseBody);
    } else if (body && name === 'recognizeResults') {
      const { status, errorMessage, results } =
        body as RecognizeResultsResponseBody;
      this.conversation.processRecognizeResultsMessage(
        body as RecognizeResultsResponseBody
      );
      this._onRecognizeResultsEvent.call(this, status, errorMessage, results);
    } else if (body && name === 'conversationResult') {
      this.conversation.onConversationResult(
        body as ConversationResultResponseBody
      );
      const { personaId } = body as ConversationResultResponseBody;
      if (personaId) {
        const persona = new Persona(this, personaId);
        const event: SmEvent = this._onConversationResultEvents[personaId];
        event.call(persona, body as ConversationResultResponseBody);
        this.currentPersonaId = personaId;
      }
    } else if (body && name === 'speechMarker') {
      this.conversation.onSpeechMarker(body as SpeechMarkerResponseBody);
      const { personaId } = body as SpeechMarkerResponseBody;
      if (personaId) {
        const persona = new Persona(this, personaId);
        const event: SmEvent = this._onSpeechMarkerEvents[personaId];
        event.call(persona, body as SpeechMarkerResponseBody);
        this.currentPersonaId = personaId;
      }
    } else if (body && name === 'demoMode') {
      this._onDemoModeEvent.call(this, body as DemoModeResponseBody);
    }

    // Process responses, message should always be a response as far as we're aware
    if (kind === WebsocketKind.Response && transaction) {
      this.processResponse(body, name, status, transaction);
    }
  }

  protected processResponse(
    body: SceneResponseBody,
    name: string,
    status: number,
    transaction: string
  ) {
    // Check for a pending response
    const pending: PendingPromise = this._pendingResponses[transaction];
    if (pending) {
      if (status === 0) {
        // Success
        pending.resolve(body);
      } else {
        // Failure
        const error = new SceneResponseError();
        error.requestName = name;
        error.status = status;
        error.responseBody = body;
        pending.reject(error);
      }

      delete this._pendingResponses[transaction];
    }
  }

  private controlMicrophoneMute(state: StateResponseBody) {
    // Watch for speaking state transitions and mute the
    // microphone during persona speech to prevent self interruption
    if (
      state.persona &&
      this._session &&
      this._session.microphoneMuteDelay !== -1
    ) {
      // iterate through the personas looking for speaking state changes
      for (const personaId in state.persona) {
        const persona_state = state.persona[personaId];
        if (!persona_state.speechState) {
          continue;
        }

        if (persona_state.speechState === SpeechState.Speaking) {
          // A persona is speaking, mute the microphone
          if (!this._session.microphoneMuted) {
            this._logger.log('warn', 'Persona is speaking - mute microphone');
            this._session.microphoneMuted = true;
          }
          if (this._microphoneUnmuteTimer) {
            // ensure an in-progress timeout doesn't incorrectly unmute
            clearTimeout(this._microphoneUnmuteTimer);
            this._microphoneUnmuteTimer = undefined;
          }
        } else {
          // A persona has stopped speaking, unmute the microphone after
          // the microphone mute delay
          if (this._session.microphoneMuted && !this._microphoneUnmuteTimer) {
            this._microphoneUnmuteTimer = setTimeout(() => {
              if (!this._session || !this._microphoneUnmuteTimer) {
                return;
              }
              this._logger.log(
                `warn`,
                'Persona is no longer speaking - unmute microphone'
              );
              this._session.microphoneMuted = false;
              this._microphoneUnmuteTimer = undefined;
            }, this._session.microphoneMuteDelay);
          }
        }
      }
    }
  }

  /** Close the current scene connection */
  private close(): void {
    // close/disconnect the session
    if (this._session) {
      this._session.close(true);
    }
  }

  private stopSpeakingWhenNotVisible = () => {
    if (document.visibilityState !== 'visible') {
      this.sendRequest('stopSpeaking', { personaId: this.currentPersonaId });
    }
  };

  private stopSpeakingWhenUnloaded = () => {
    this.sendRequest('stopSpeaking', { personaId: this.currentPersonaId });
  };

  private sessionConnected(
    resumeRequested: boolean,
    isResumedSession: boolean,
    server: string,
    sessionId: string
  ) {
    this.contentAwareness = new ContentAwareness(
      this as Scene,
      this.contentAwarenessDebounceTime,
      new Logger(
        this._loggingConfig.contentAwareness.minLogLevel,
        this._loggingConfig.contentAwareness.enabled
      )
    );

    /*
    Interrupt DP from speaking so it does not continue talking when user switches tabs
    */
    if (this._stopSpeakingWhenNotVisible) {
      document.addEventListener(
        'visibilitychange',
        this.stopSpeakingWhenNotVisible
      );
    }

    //when user navigates to a new page, widget DP should stop speaking to allow new welcome message coming through in the new page
    //however browser doesn't trigger visibilitychange event, only "beforeunload" event is sure to be triggered
    window.addEventListener('beforeunload', this.stopSpeakingWhenUnloaded);

    if (this._sendMetadata.pageUrl) {
      this._metadataSender.observeUrlChanges();
    }

    //update resume session data
    this._isResumedSession = isResumedSession;
    if (resumeRequested) {
      this._sessionResumeEnabled = true;
      storeSessionData(server, sessionId, this._apiKey || '');
    }

    // When page navigation happens, check if any value in _sendMetadata is true and send it back to NLP so conversation writers can use it
    if (isResumedSession && this._sendMetadata.pageUrl) {
      this._metadataSender.send();
    }
  }

  private cleanupEventListeners() {
    if (this._session?.features.isIos) {
      document.removeEventListener(
        'visibilitychange',
        this.iosVisibilityChange
      );
    }
    if (this._stopSpeakingWhenNotVisible) {
      document.removeEventListener(
        'visibilitychange',
        this.stopSpeakingWhenNotVisible
      );
    }
    window.removeEventListener('beforeunload', this.stopSpeakingWhenUnloaded);
  }

  private sessionClosed(reason: string) {
    clearSessionData();
    this.cleanupEventListeners();
    if (this._session) {
      this.connectionState.reset();
      this.conversation.reset();
      this._onDisconnectedEvent.call(this, this._session.sessionId, reason);
    }
  }

  private rtcUserText(text: string) {
    this._onUserTextEvent.call(this, text);
  }
  private enableFlaggedFeatures(featureFlags: FeatureFlag[]) {
    this._serverControlledCameras = featureFlags.includes(
      FeatureFlag.UI_SDK_CAMERA_CONTROL
    );
  }

  public sendContent() {
    if (!this.contentAwareness) {
      console.warn('ContentAwareness is not enabled for this project');
    }
    this.contentAwareness?.measure();
  }

  /**
   * Sends updated video element size to server
   * this gives the app the chance to choose what size should be rendered on server
   * and the application is responsible to register for a video element size change
   * event and call this method to maintain best possible video quality for the size
   * and/or to set an updated video element size and then call this method.
   * @param width - The width in pixels to render the video
   * @param height - The height in pixels to render the video
   */
  public sendVideoBounds(width: number, height: number) {
    if (this._session) {
      this._session.sendVideoBounds(width, height);
    }
  }

  /**
   * Send configuration to the scene
   * @param configuration - Scene configuration as per the scene protocol
   */
  public configure(configuration: ConfigurationModel): Promise<any> {
    return this.sendRequest('configure', configuration);
  }

  /**
   * Send a custom user text message to the orchestration server
   * @param text - Custom text sent to the orchestration server
   */
  private sendUserText(text: string) {
    if (this._session) {
      this._session.sendUserText(text);
    }
  }

  /**
   * Start the speech to text recognizer
   * @param audioSource - The audio source either smwebsdk.audioSource.processed or
   *                    smwebsdk.audioSource.squelched, defaults to processed.
   */
  public startRecognize(audioSource?: AudioSourceTypes): Promise<any> {
    const body: StartRecognizeRequestBody = {};

    if (audioSource !== undefined) {
      body.audioSource = audioSource;
    }

    return this.sendRequest('startRecognize', body);
  }

  /** Stop the speech to text reconizer */
  public stopRecognize(): Promise<any> {
    return this.sendRequest('stopRecognize');
  }

  /** Is the microphone connected in the session */
  public isMicrophoneConnected(): boolean | null {
    // public function rather than getter for back compatibility
    if (this._session) {
      return this._session.isMicrophoneConnected;
    }
    return false;
  }

  /** Is the camera connected in the session */
  public isCameraConnected(): boolean | null {
    // public function rather than getter for back compatibility
    if (this._session) {
      return this._session.isCameraConnected;
    }
    return false;
  }

  public session(): Session | LocalSession | WebSocketSession | undefined {
    // public function rather than getter for back compatibility
    return this._session;
  }

  public hasContentAwareness(): boolean {
    return !!this.contentAwareness;
  }

  public hasServerControlledCameras(): boolean {
    return this._serverControlledCameras;
  }

  /**
   * Check if session persistence feature is supported in current session
   *
   * @returns `boolean`
   *
   * Usage:
   * ```javascript
   * const isSessionPersistenceSupported = scene.supportsSessionPersistence();
   * ```
   */
  public supportsSessionPersistence(): boolean {
    return this._sessionResumeEnabled;
  }

  /**
   * Check if current session is a new session or a resumed session
   *
   * @returns `boolean`
   *
   * Usage:
   * ```javascript
   * const isResumedSession = scene.isResumedSession();
   * ```
   */
  public isResumedSession(): boolean {
    return this._isResumedSession;
  }

  get onConversationResultEvents(): PersonaEventMap {
    return this._onConversationResultEvents;
  }

  get onSpeechMarkerEvents(): PersonaEventMap {
    return this._onSpeechMarkerEvents;
  }

  /** Get the current scene state */
  public async getState(): Promise<any> {
    return this.sendRequest('getState');
  }

  get onStateEvent(): SmEvent {
    return this._onStateEvent;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  set onState(onState: Function) {
    this._onState = onState;
  }

  get onDisconnectedEvent(): SmEvent {
    return this._onDisconnectedEvent;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  set onDisconnected(onDisconnected: Function) {
    this._onDisconnected = onDisconnected;
  }

  get onRecognizeResultsEvent(): SmEvent {
    return this._onRecognizeResultsEvent;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  set onRecognizeResults(onRecognizeResults: Function) {
    this._onRecognizeResults = onRecognizeResults;
  }

  get onUserTextEvent(): SmEvent {
    return this._onUserTextEvent;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  set onUserText(onUserText: Function) {
    this._onUserText = onUserText;
  }

  public get echoCancellationEnabled() {
    return this._echoCancellationEnabled;
  }

  public set echoCancellationEnabled(enabled: boolean) {
    this._echoCancellationEnabled = enabled;
  }

  /**
   * @internal
   */
  get onDemoModeEvent(): SmEvent {
    return this._onDemoModeEvent;
  }

  get videoElement(): HTMLVideoElement | undefined {
    return this._videoElement;
  }

  set videoElement(videoElement: HTMLVideoElement | undefined) {
    this._videoElement = videoElement;

    // cast HTMLVideoElement | undefined to HTMLElement,
    // not ideal, but this is same to when session is constructed.
    this._session?.setVideoElement(this._videoElement as HTMLVideoElement);
  }

  get viewerOffsetX(): number {
    if (this._session) {
      return this._session.offsetX;
    }
    return 0;
  }

  get viewerOffsetY(): number {
    if (this._session) {
      return this._session.offsetY;
    }
    return 0;
  }

  get isWebSocketOnly(): boolean {
    return this._isWebSocketOnly;
  }

  set isWebSocketOnly(isWebSocketOnly: boolean) {
    this._isWebSocketOnly = isWebSocketOnly;
  }

  /**
   * @returns an {@link SmEvent} associated with the microphone.
   *
   * Listeners can then be added to this event allowing you to call functions when the microphone active status changes.
   *
   * Usage:
   * ```javascript
   * scene.onMicrophoneActive.addListener(
   *   (active) => console.log('Microphone Active: ', active));
   * ```
   */
  public get onMicrophoneActive(): SmEvent {
    return this._onMicrophoneActive;
  }

  /**
   * Specifies if the microphone is currently active and streaming audio
   * to the server.
   *
   * @returns `boolean`
   *
   * Usage:
   * ```javascript
   * const isMicrophoneActive = scene.isMicrophoneActive();
   * ```
   */
  public isMicrophoneActive(): boolean {
    return Boolean(this._session?.isMicrophoneActive());
  }

  /**
   * @returns an {@link SmEvent} associated with the camera.
   *
   * Listeners can then be added to this event allowing you to call functions when the camera active status changes.
   *
   * Usage:
   * ```javascript
   * scene.onCameraActive.addListener(
   *   (active) => console.log('Camera Active: ', active));
   * ```
   */
  public get onCameraActive(): SmEvent {
    return this._onCameraActive;
  }

  /**
   * Specifies if the camera is currently active and streaming video
   * to the server.
   *
   * @returns `boolean`
   *
   * Usage:
   * ```javascript
   * const isCameraActive = scene.isCameraActive();
   * ```
   */
  public isCameraActive(): boolean {
    return Boolean(this._session?.isCameraActive());
  }

  /**
   * On success, starts or stops streaming video/audio to the server based on the values of `microphone` and `camera`.
   *
   * @param options.microphone - If `true`, activates the microphone and starts streaming audio. \
   * If `false` deactivates the microphone and stops streaming audio. \
   * If not set, microphone will retain its existing state.
   * @param options.camera - If `true`, activates the camera and starts streaming video. \
   * If `false` deactivates the camera and stops streaming video. \
   * If not set, microphone will retain its existing state.
   *
   * @returns Returns a promise which is fulfilled when the media active state has been successfully changed. \
   * If the session is not defined it will return `undefined`. \
   * If the active state could not be changed, the promise is rejected with an Error object having the format:
   * ```javascript
   * {
   *   message: string;
   *   name: errorCode;
   * }
   * ```
   * Where `errorCode` is one of:
   *    - `noUserMedia` - the microphone/camera is either not available, not usable or the user declined permission to use them
   *    - `failedUpgrade` - the media upgrade failed
   *    - `notSupported` - user’s browser does not support the getUserMedia API
   *    - `noConnection` - connection has not been established - ensure scene.connect() has been called previously
   *
   * Usage:
   * ```javascript
   * scene.setMediaDeviceActive({ microphone: true, camera: false })
   *   .then(console.log('microphone activated, camera deactivated'));
   *   .catch((error) => console.log('error occurred: ', error);
   * ```
   */
  public async setMediaDeviceActive(options: {
    microphone?: boolean;
    camera?: boolean;
  }): Promise<void> {
    if (this.isConnected()) {
      await this._session?.setMediaDeviceActive({
        microphone: options.microphone,
        camera: options.camera,
      });
    } else {
      throw makeError('Connection has not been established', 'noConnection');
    }
  }

  /**
   * Play the video element and return results. Different browsers have different restrictions on autoplay.
   * Using this method can handle all the cases browsers can have on inital video playback.
   * @param videoElement - Optional parameter specifying the video element hosting the Digital Person. If not specified the video element passed to the Scene constructor will be used.
   * @returns Returns a promise which is fulfilled when the video playback is successful, with indication of video and audio status.
   * If the video element is not defined or video play fails the promise is rejected with an Error object having the format:
   * ```javascript
   * {
   *   message: string;
   *   name: errorCode;
   * }
   * ```
   * Where `errorCode` is one of:
   *    - `noVideoElement` - no HTMLVideoElement found from `videoElement` or `Scene` constructor
   *    - `userInteractionRequired` - cannot start media playback due to browser restriction; user interaction is required before playing again
   *
   * Usage:
   * ```javascript
   * scene.startVideo()
   *      .then(({ video, audio }) => {
   *         if (!audio) {
   *          //video is muted, ask user to unmute video
   *         }
   *      })
   *      .catch((error) => {
   *         if (error.name === 'userInteractionRequired') {
   *          //ask user to interact with the UI
   *          //unmute video and play again
   *          video.muted = false;
   *          video.play();
   *         }
   *      });
   * ```
   */
  public async startVideo(
    videoElement?: HTMLVideoElement
  ): Promise<{ video: boolean; audio: boolean } | Error> {
    const video = videoElement || this._videoElement;
    if (!video) {
      throw makeError('Cannot find HTMLVideoElement', 'noVideoElement');
    }
    // best case, play with audio
    if (await this.playVideo(video)) {
      return {
        video: true,
        audio: true,
      };
    }
    //second-best case, play without audio
    video.muted = true;
    if (await this.playVideo(video)) {
      return {
        video: true,
        audio: false,
      };
    }
    //worst case, not able to play, require user interaction
    throw makeError('Cannot start media playback', 'userInteractionRequired');
  }

  private async playVideo(videoElement: HTMLVideoElement): Promise<boolean> {
    try {
      await videoElement.play();
      return true;
    } catch {
      return false;
    }
  }

  private fetchAuthConfig(apiKey: string) {
    let authServer: string;
    try {
      const decodedApiKey: { authServer: string } = JSON.parse(atob(apiKey));
      authServer = decodedApiKey.authServer;
    } catch (error) {
      throw makeError('Broken API key', 'Failed to decode api key');
    }
    // check if sessionId exists in browser storage
    const { server, resumeSessionId, savedApiKey } = getSessionData();
    // check if the current api key is same as saved api key (if it is intended to resume to the same DP)
    if (server && resumeSessionId && savedApiKey === apiKey) {
      authServer = authServer + '?sessionId=' + resumeSessionId;
    }
    return fetch(authServer, {
      headers: {
        key: apiKey,
      },
    });
  }

  // Use the first parameter of the constructor to figure out
  // whether it was constructed using ConnectOptions, or using the deprecated multi-param format.
  private connectArgsToConfig(
    serverUriOrOptions?: string | ConnectOptions,
    userText?: string,
    accessToken?: string,
    retryOptions?: RetryOptions
  ) {
    if (typeof serverUriOrOptions === 'string') {
      return {
        tokenServerUri: serverUriOrOptions,
        tokenServerAccessToken: accessToken,
        userText,
        retryOptions,
      };
    } else {
      return {
        tokenServerUri: serverUriOrOptions?.tokenServer?.uri || '',
        tokenServerAccessToken: serverUriOrOptions?.tokenServer?.token,
        userText: serverUriOrOptions?.userText,
        retryOptions: serverUriOrOptions?.retryOptions,
      };
    }
  }

  /**
   * Check if the session logging is enabled.
   *
   * @returns Returns true if the session logging is enabled otherwise false.
   */
  public isLoggingEnabled(): boolean {
    return this._logger.isEnabled;
  }

  /**
   * Check minimal log level of session logging.
   *
   * @returns Returns minimal log setting of session logging, type is LogLevel.
   */
  public getMinLogLevel() {
    return this._logger.getMinLogLevel();
  }

  /**
   * Enable/disable session logging
   * @param enable - set true to enable session log, false to disable
   */
  public setLogging(enable: boolean) {
    this._logger.enableLogging(enable);
  }

  /**
   * Set minimal log level of session logging.
   * @param level - use LogLevel type to set minimal log level of session logging
   */
  public setMinLogLevel(level: LogLevel) {
    this._logger.setMinLogLevel(level);
  }
}
